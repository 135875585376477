import { createSlice } from "@reduxjs/toolkit";
import { isEmpty, uniqWith, flatten, values, pickBy, groupBy } from "lodash";
import moment from "moment";
import {
	BranchUtil,
	getCheckReceipts,
	UserInfoUtil,
	TerminalUtil,
	addTransactionCheckReceiptNo,
} from "../../utils/checkSession";
import { retry_f } from "../../utils/client";
import { getSessionStorage, setSessionStorage } from "../../utils/storage";
import { setIsDisabledPartnerDialogOpen } from "./partnerList";
import { setTimeoutError, timeoutErrorCodes } from "./validationBuyLoad";
import { getTransactionsSummary } from "../thunks/transaction";
import { removeSpecialCharAndSpace } from "../../utils/utils";
import api from "../../utils/api3Client";
const API3_URL = process.env.REACT_APP_API3_ENDPOINT;

const paymentStatuses = {
	PAYMENT_POSTED: "Success",
	PAYMENT_DUPLICATE: "Duplicate Payment",
	FAILED: "Failed",
	Success: "Success",
	VALIDATION_NOT_FOUND: "Failed",
	TXN_GROUP_INVALID_STATUS: "Failed",
	CUSTOMER_DETAILS_NOT_VALID: "Failed",
	API3_ERROR_PAYMENTS: "Failed",
	PARTNER_API_ERROR_PAYMENTS: "Failed",
	INQUIRE_EXCEPTION: "Failed",
	GENERIC_ERROR: "Failed",
};

const initialState = {
	successPayment: {},
	paymentFields: {},
	isLoading: false,
	isSuccess: false,
	showViewReceiptModal: false,
	showTransactionList: false,
	selectedForPrint: { index: 0, values: {} },
	showPrintBillReceipt: false,
	transactionList: [],
	checkReceiptsList: [],
	paymentErrorCode: {
		code: null,
		message: null,
		displayType: null,
		header: null,
	},
	processingPaymentMessage: null,
	successMessage: null,
	checkDuplicate: true,
	duplicateTransactionList: [],
	refreshList: false,
	summary: [
		{
			name: "No. of Transactions",
			value: "--",
		},
		{
			name: "Total no. of CHECK",
			value: "--",
		},
		{
			name: "Total no. of CASH",
			value: "--",
		},
		{
			name: "Total no. of CASH + CHECK",
			value: "--",
		},
		{
			name: "Total Amount",
			value: "--",
		},
	],
};

const transactionBuyLoad = createSlice({
	name: "transactionBuyLoad",
	initialState,
	reducers: {
		prep: (state, payload) => {
			state.processingPaymentMessage = null;
			let newPaymentFields = {};
			Object.keys(payload.paymentFields).forEach((code) => {
				let sanitizedCode = removeSpecialCharAndSpace(code); //remove special characters and space
				newPaymentFields[sanitizedCode] = [];
				payload.paymentFields[sanitizedCode].forEach((valid) => {
					console.log("valid>>>>>", valid);

					let paymentData = {
						info: {
							validationNumber: valid.validationNumber,
						},
						other: valid.otherInfo,
						clientReferenceNumber: valid.clientReferenceNumber,
						transactionId: valid.transactionId,
						fee: valid.otherCharges,
						amount: valid.amount,

						// for validate wallet commit
						otherCharges: valid.otherCharges,
						paymentMethod: valid.paymentMethod,
						referenceNumber: valid.referenceNumber,
						transactionKey: valid.transactionKey,
						sequenceNumber: valid.sequenceNumber,
					};
					newPaymentFields[sanitizedCode].push(paymentData);
				});
			});
			state.paymentFields = newPaymentFields;
			state.transactionList = [];
			state.isLoading = true;
		},
		load: (state, payload) => {
			state.transactionList = payload.result.txnList.sort(
				(a, b) =>
					parseInt(a.sequenceNumber) - parseInt(b.sequenceNumber)
			);
			state.checkReceiptsList = payload.result.checkList;
			state.isLoading = false;
			state.isSuccess = true;
		},
		error: (state, payload) => {
			if (payload.result.errors) {
				state.errorValidation = payload.result.errors;
			} else {
				state.errorValidation = { legacyError: payload.result.details };
			}
			state.isLoading = false;
			state.isSuccess = false;
			state.processingPaymentMessage = null;
		},
		toggleTransactionSummary: (state, { payload }) => {
			console.log("transaction state in redux>>", state);
			console.log("transaction list in redux payload>>", payload);
			state.showTransactionList = payload;
			state.showViewReceiptModal = !state.showViewReceiptModal;
		},
		updateSelectedForPrint: (state, { payload }) => {
			console.log(
				"state: transaction selected for print in redux>>",
				state
			);
			console.log(
				"state: transaction selected for print in redux>>",
				payload
			);

			state.selectedForPrint = payload;
			state.showPrintBillReceipt = true;
		},
		updateTransactionList: (state, { payload }) => {
			const index = payload.index;
			const status = payload.status;
			state.transactionList[index]["printStatus"] = status["status"];
		},
		updateCheckList: (state, { payload }) => {
			const index = payload.index;
			const status = payload.status;
			state.checkReceiptsList[index]["printStatus"] = status;
		},
		showOrHideTransactionSummary: (state, { payload }) => {
			state.showTransactionList = payload;
		},
		togglePrintBillReceipt: (state, { payload }) => {
			state.showPrintBillReceipt = payload;
		},
		showPrintReceiptModal: (state, { payload }) => {
			state.showPrintReceiptModal = payload.show;
			state.selectedForPrint = payload.data;
		},
		disablePrintButton: (state, { payload }) => {
			state.transactionList = payload.data;
		},
		printCheckFlowModal: (state, { payload }) => {
			state.printCheckShow = payload.show;
		},
		printCheckStatus: (state, { payload }) => {
			state.printCheckStatus = payload.status;
		},
		setPaymentErrorCode: (state, { payload }) => {
			state.paymentErrorCode = payload;
		},
		setProcessingPaymentMessage: (state, { payload }) => {
			state.processingPaymentMessage = payload;
		},
		setSuccessMessage: (state, { payload }) => {
			state.successMessage = payload;
		},
		setCheckDuplicate: (state, { payload }) => {
			state.checkDuplicate = payload;
		},
		setDuplicateTransactionList: (state, { payload }) => {
			state.duplicateTransactionList = payload;
		},
		setRefreshList: (state, { payload }) => {
			state.refreshList = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getTransactionsSummary.fulfilled, (state, action) => {
			state.summary = action.payload;
		});
	},
});

const {
	prep,
	load,
	error,
	toggleTransactionSummary,
	updateSelectedForPrint,
	updateTransactionList,
	updateCheckList,
	showOrHideTransactionSummary,
	togglePrintBillReceipt,
	showPrintReceiptModal,
	disablePrintButton,
	printCheckFlowModal,
	printCheckStatus,
	setPaymentErrorCode,
	setCheckDuplicate,
	setDuplicateTransactionList,
	setProcessingPaymentMessage,
	setSuccessMessage,
	setRefreshList,
} = transactionBuyLoad.actions;

const createPayment = async (client, state, dispatch) => {
	console.log("11111 client>>>", client);
	console.log("22222 state>>>", state);
	console.log("33333 dispatch>>>", dispatch);

	if (isEmpty(state.validationBuyLoad.currentTxnGroupNo))
		throw {
			message: "No group trx ref number. Please validate partners first.",
		};

	const { id: branch_id, name: branchName } = BranchUtil.get()[0];
	const terminal = TerminalUtil.get();
	let customerDetails =
		state.validationBuyLoad.paymentDetails.otherInfo.CustomerDetails;
	const { selectedPaymentMethod: paymentMethod } = state.partnerList;
	const codes = Object.keys(state.transactionBuyLoad.paymentFields);
	const PRODUCT_PARTNER_DISABLED_MESSAGE =
		"This Product Partner is currently disabled. Please contact Bayad Helpdesk or your Bayad admin support to enable this product partner in the BIP Portal.";

	let { username } = UserInfoUtil.get();
	let requestArray = [];

	codes.flatMap((code) => {
		let partner = state.partnerList.selectedPartners.find(
			(partner) => partner.code === code
		);

		state.transactionBuyLoad.paymentFields[code].map((payment) => {
			let paymentData = {
				info: payment.info,
				fee: payment.otherCharges,
				otherInfo: payment.other,
				payment_method: paymentMethod,
				code: code,

				// added for validate wallet commit
				amount: payment.amount,
				otherCharges: payment.otherCharges,
				paymentMethod: paymentMethod,
				referenceNumber: payment.referenceNumber,
				transactionKey: payment.transactionKey,
				sequenceNumber: payment.sequenceNumber,
			};
			requestArray.push(paymentData);
		});
	});

	dispatch(
		setProcessingPaymentMessage(
			"Please wait while we validate your payment request..."
		)
	);

	const hasDuplicateTrans = flatten(
		values(
			pickBy(
				groupBy(
					requestArray,
					(item) =>
						`${item.code}-${item.referenceNumber}-${item.amount}`
				),
				(x) => x.length > 1
			)
		)
	);

	const transUnique = uniqWith(hasDuplicateTrans, function (arrVal, othVal) {
		return (
			arrVal.code === othVal.code &&
			arrVal.referenceNumber === othVal.referenceNumber &&
			Number.parseFloat(arrVal.amount) ===
				Number.parseFloat(othVal.amount)
		);
	});

	let duplicateList = [];
	if (transUnique.length > 0) {
		transUnique.forEach((data) => {
			let duplicateDate = {};
			let partner = state.partnerList.selectedPartners.find((partner) => {
				return partner.code === data.code;
			});
			duplicateDate["partnerName"] = partner.name;
			duplicateDate["accountNo"] = data["referenceNumber"];
			duplicateDate["amount"] = data["amount"];
			duplicateDate["timestamp"] = moment(new Date()).format(
				"MMMM DD, YYYY / hh:mm A"
			);
			duplicateDate["newTransaction"] = true;

			duplicateList.push(duplicateDate);
		});
		dispatch(setDuplicateTransactionList(duplicateList));
	}

	if (transUnique.length > 0) {
		dispatch(
			setPaymentErrorCode({
				code: "DUP_TRANS",
				message:
					"You just processed a transaction with the same details. Kindly try after a few seconds.",
			})
		);
		throw { message: "Duplicate Payment", data: duplicateList };
	}

	let walletValidatePayload = {
		commit_payment: true,
		payment_details: requestArray,
		group_ref_num: state.validationBuyLoad.currentTxnGroupNo,
		payment_method: paymentMethod,
		branch_id,
		terminal_id: terminal.id,
	};
	if (!isEmpty(customerDetails)) {
		walletValidatePayload = {
			...walletValidatePayload,
			customerDetails: customerDetails,
		};
	}
	await client
		.post("/v3/wallet/validate", JSON.stringify(walletValidatePayload))
		.then(({ data }) => data)
		.catch(({ response }) => {
			console.log("4444 validate API response>>>", response);
			/* If timed out */
			if (timeoutErrorCodes.includes(response.status)) {
				dispatch(
					setTimeoutError({
						code: "TIME_OUT",
						statusCode: response.status || 504,
						message: "Transaction timed out",
					})
				);

				throw { message: "Wallet Verify Timeout" };
			}

			if (response.status === 400) {
				dispatch(setIsDisabledPartnerDialogOpen(true));
				throw { message: "Product not available" };
			}

			/* If has product/channel wallet error, do not continue */
			if (response.status === 404) {
				dispatch(
					setPaymentErrorCode({
						code: "TXN_PRODUCT_ERROR",
						message: `${PRODUCT_PARTNER_DISABLED_MESSAGE}`,
					})
				);
				throw { message: "Transaction Error!" };
			}
			if (response.status === 406) {
				dispatch(
					setPaymentErrorCode({
						code: "TXN_PRODUCT_ERROR",
						message: `${PRODUCT_PARTNER_DISABLED_MESSAGE}`,
					})
				);
				throw { message: "Transaction Error!" };
			}

			if (response.status === 422) {
				const txnValidation = response?.data?.txn_validation;
				const channelValidation = response?.data?.channel_wallet;
				const walletType = response?.data?.wallet_type;

				if (!isEmpty(txnValidation) || !isEmpty(channelValidation)) {
					if (!isEmpty(txnValidation)) {
						dispatch(
							setPaymentErrorCode({
								code: "TXN_WALLET_ERROR",
								message:
									"The wallet balance of this product is insufficient to process the amount for this transaction.",
							})
						);
						throw { message: "Product Wallet error" };
					}
					if (
						!isEmpty(channelValidation) &&
						walletType !== "bonded"
					) {
						dispatch(
							setPaymentErrorCode({
								code: "CHL_WALLET_ERROR",
								message:
									"The wallet balance is insufficient to process the amount for this transaction.",
							})
						);
						throw { message: "Channel Wallet error" };
					}
				}
			} else {
				throw { message: "Product/Channel Wallet error" };
			}
			/*if bonded, allow to process */
		});

	/* Update group_ref_num to success */
	await client.put(
		`/v2/billers/transaction-group/${state.validationBuyLoad.currentTxnGroupNo}`,
		{
			paymentStatus: "COMMIT_PAYMENT",
		}
	);

	dispatch(
		setProcessingPaymentMessage(
			"We are processing your transaction,\nplease do not refresh the page. If an error is encountered,\n always check the Transaction History before re-processing\nany transaction. Thank you!"
		)
	);

	let verifyResponses = [];
	let unsuccessfullRequests = [];
	let commitPaymentStatus;
	let commitPaymentData;

	await Promise.all(
		requestArray.map(
			async (request) =>
				new Promise((resolve) => {
					// /* Commit Paument */
					if (!isEmpty(customerDetails)) {
						request = {
							...request,
							customerDetails: customerDetails,
						};
					}
					const body = JSON.stringify({
						...request,
						txnGrpRefNumber:
							state.validationBuyLoad.currentTxnGroupNo,
						checkDuplicate: state.transactionBuyLoad.checkDuplicate
							? "True"
							: "False",
					});

					console.log("5555 commit payment payload>>>>", body);
					/* Verify billers and send group_ref_num */
					api.post(
						`${API3_URL}/v3/buyload/${request.code}/payments`,
						body
					)
						.then(async ({ data }) => {
							console.log("6666 payment API resp>>>", data);
							verifyResponses.push({
								body: { ...data, ...request },
							});

							console.log("7777 payment API resp body>>>", body);

							resolve({ success: 1 });
						})
						.catch((error) => {
							console.log("8888 payment API error>>>", error);

							commitPaymentStatus = error?.response?.status;
							commitPaymentData = error?.response?.data;
							request = {
								...request,
								error: error?.response?.data ?? null,
								statusCode:
									error?.response?.data?.errorStatusCode ??
									504,
								errorCode:
									error?.response?.data?.errorCode ?? null,
							};
							if (commitPaymentStatus === 422) {
								verifyResponses.push({
									body: {
										...commitPaymentData,
										...request,
										errorCode: commitPaymentData?.errorCode,
									},
								});
							} else {
								/* Return successful validations */
								unsuccessfullRequests.push({
									request,
									body,
									status: commitPaymentStatus || 504,
								});
							}
							resolve({ success: 1 });
						});
				})
		)
	);

	/* Call inquire API unsuccessful partners */
	if (unsuccessfullRequests.length > 0) {
		await Promise.all(
			unsuccessfullRequests.map(
				async ({ request, status }) =>
					new Promise((resolve, _) => {
						/* Inquire 4 times, 30sec timeout, configurable */
						const options = {
							statusCodes: timeoutErrorCodes,
							status,
							initialTries: 0,
							tries: 3,
							initialTimeout: 0,
							timeout: 0,
							checkPending: true,
						};
						const callback = async () => {
							/* Excute inquire API */
							const crn =
								state.validationBuyLoad.currentPartnerValidations.find(
									(bv) =>
										bv.body.transactionKey ===
										request.transactionKey
								)?.body?.data?.transactionId ?? null;
							const inquire = await api.get(
								`${API3_URL}/v3/buyload/${request.code}/payments/${crn}`
							);

							console.log(
								"retry inquire api response>>>",
								inquire
							);
							/* When pending and processing, loop it again */
							if (
								["PENDING", "PROCESSING"].includes(
									inquire?.data?.data?.status
								)
							) {
								return inquire;
							}
							/**/

							/* If success or other statuses, proceed */
							verifyResponses.push({
								body: { ...inquire.data, ...request },
							});
							resolve({ success: true });
						};

						/* looper function for checking timeout errors */
						retry_f(options, callback).catch(
							(e) => {
								console.log("Retry Error: ", e);
								verifyResponses.push({
									body: {
										...request,
										data: { status: "retry_error" },
									},
								});
								return resolve({ success: true });
							}
							/**/
						);
					})
			)
		);
	}

	const response = { data: verifyResponses };

	console.log("9999 final response>>>>", response);

	const printingTransactionStatus = await client.get(
		`/v2/receipts?status=pending`
	);

	console.log(
		"*1111 printingTransactionStatus >>>>",
		printingTransactionStatus
	);

	const trxnsReceiptData =
		printingTransactionStatus["data"]["data"]["txn_receipts"];
	const checksReceiptData =
		printingTransactionStatus["data"]["data"]["check_receipts"];

	console.log("*2222 trxnsReceiptData >>>>", trxnsReceiptData);

	console.log("*3333 checksReceiptData >>>>", checksReceiptData);

	let checkList = checksReceiptData
		? await getCheckReceipts(checksReceiptData)
		: [];

	console.log("*4444 checkList >>>>", checkList);

	let txnList = [];

	// Extract the valid keys from paymentStatuses
	const allowedStatuses = Object.keys(paymentStatuses);

	console.log("10101010 final response>>>>", response);

	response.data.forEach((paymentResponse) => {
		const code = paymentResponse.body.code;
		if (
			paymentResponse?.body?.data?.status === "Success" ||
			paymentResponse?.body?.data?.status === "PAYMENT_POSTED"
		) {
			const resData = paymentResponse.body.data;
			let partner = state.partnerList.selectedPartners.find(
				(partner) => partner.code === code
			);
			// Validate that resData.status is a valid key
			let paymentStatus = allowedStatuses.includes(resData.status)
				? paymentStatuses[resData.status]
				: resData.status; // Fallback value or resData.status
			let ret = {
				partnerName: partner.name,
				accountNo: resData.referenceNumber,
				transactionNumber: resData.transactionId,
				amount: resData.amount,
				paymentStatus: paymentStatus,
				printStatus: "PENDING",
				paymentMethod: resData.paymentMethod,
				fee: resData.fee,
				createdAt: resData.createdAt,
				otherInfo: resData.otherInfo,
				username,
				code: resData.code,
				branchName,
				clientReference: resData.clientReference,
				channelName: resData.channelName,
				receiptNo: resData?.txn_receipt || resData.receiptNo, // retry | payment
				crn: resData?.clientReference || resData.transactionId, // retry | payment,
				trn: resData.trn,
				sequenceNumber: paymentResponse.body.sequenceNumber,
				transaction_status: "success",
			};

			setSessionStorage("previousSuccessfulPayments", [
				...(getSessionStorage("previousSuccessfulPayments") || []),
				{
					partnerCode: ret.code,
					accountNo: ret.accountNo,
					amount: ret.amount,
					timestamp: moment(),
				},
			]);

			console.log("rettt>>>>>>", ret);

			txnList.push(ret);
		} else {
			console.log("Payment Response Error: ", { ...paymentResponse });
			let partner = state.partnerList.selectedPartners.find((partner) => {
				return partner.code === code;
			});

			const note = paymentResponse?.reason?.response?.request ?? {};
			let paymentErrorStatus = allowedStatuses.includes(
				paymentResponse.body.errorCode
			)
				? paymentStatuses[paymentResponse.body.errorCode]
				: paymentResponse.body.errorCode; // Fallback value or resData.status
			txnList.push({
				partnerName: partner.name,
				accountNo: paymentResponse.body.referenceNumber,
				transactionNumber: "",
				code: paymentResponse.body.code,
				amount: paymentResponse.body.amount,
				paymentStatus: paymentErrorStatus || "Failed",
				printStatus: "FAILED",
				paymentMethod: paymentResponse.body?.referenceNumber || "CASH",
				modeOfPayment: paymentResponse.body?.paymentMethod,
				fee: partner.otherCharges,
				createdAt: "",
				note: note.details?.message ?? "",
				sequenceNumber: paymentResponse.body?.sequenceNumber,
				transaction_status: "failed",
				checkNumberExist:
					paymentResponse.body?.error?.data?.checkNumberExist ?? 0,
				message: paymentResponse.body?.data?.message ?? "",
				statusCode: paymentResponse.body?.statusCode ?? 504,
				retryError:
					paymentResponse.body?.data?.status === "retry_error"
						? true
						: false,
				error: paymentResponse.body?.error ?? null,
				errorCode: paymentResponse.body?.errorCode ?? null,
				timestamp:
					paymentResponse.body?.error?.data?.created_at ?? null,
			});
		}
	});

	if (txnList[0].paymentMethod === "CHECK")
		addTransactionCheckReceiptNo(txnList, checkList);

	console.log("9999 transaction list>>>>>", txnList);
	txnList.forEach((txn) => {
		if (
			txn.crn &&
			(txn.receiptNo === null || txn.receiptNo === undefined)
		) {
			txn.receiptNo = trxnsReceiptData.find(
				(t) => t.transaction_id === txn.crn
			).receipt_no;
			// txn.receiptNo = 100;
		}
	});

	const successTxnLength = txnList
		.slice()
		.filter((t) => t.transaction_status === "success").length;

	const failedTxnList = txnList
		.slice()
		.filter((t) => t.transaction_status === "failed");
	const failedTxnLength = failedTxnList.length;

	const dupTxnList = txnList
		.slice()
		.filter((t) => t.errorCode === "PAYMENT_DUPLICATE");
	const dupTxnLength = dupTxnList.length;

	if (dupTxnLength === txnList.length) {
		dispatch(setDuplicateTransactionList([...dupTxnList]));
		dispatch(
			setPaymentErrorCode({
				code: "DUP_TRANS",
				message:
					"You just recently processed a transaction with the same details. Are you sure you want to proceed?",
			})
		);
		throw { message: "Duplicate Payment!", dupTxnList };
	}

	if (
		failedTxnLength === txnList.length &&
		txnList[0].modeOfPayment === "CHECK" &&
		txnList[0].checkNumberExist === 1
	) {
		dispatch(
			setPaymentErrorCode({
				code: "TXN_ERR",
				message:
					"Error 422: Commit Payment - Check number must be unique and not used in recent transaction. Kindly try to commit payment again.",
				displayType: "BANNER",
			})
		);
		throw { message: "Transaction Error!" };
	} else if (
		failedTxnLength === txnList.length &&
		txnList[0].checkNumberExist === 0
	) {
		let message =
			"We encountered a problem while processing your payment. Kindly retry to commit payment in a few minutes.";
		let displayType = "MODAL";
		let header = null;

		if (
			failedTxnList[0].statusCode === 400 &&
			failedTxnList[0]?.error?.errorCode === "VALIDATION_NOT_FOUND"
		) {
			message =
				"Error 400: Commit Payment - Payment validation error. Kindly try to validate payment again.";
			displayType = "BANNER";
		} else if (
			failedTxnList[0].statusCode === 422 &&
			(failedTxnList[0]?.error?.errorCode === "TXN_GROUP_NOT_FOUND" ||
				failedTxnList[0]?.error?.errorCode ===
					"TXN_GROUP_INVALID_STATUS")
		) {
			message =
				"Error 422: Commit Payment - Transaction group error. Kindly try to validate payment again.";
			displayType = "BANNER";
		} else if (
			failedTxnList[0].statusCode === 500 &&
			failedTxnList[0]?.error?.errorCode === "API3_ERROR_PAYMENTS"
		) {
			message =
				"Error 500: Commit Payment - Create payment failed (API 3.0). Kindly try to commit payment again.";
			displayType = "BANNER";
		} else if (
			failedTxnList[0].statusCode === 422 &&
			failedTxnList[0]?.error?.errorCode === "BILLER_API_ERROR_PAYMENTS"
		) {
			message = `Error 422: Commit Payment - Partner API returned error ${failedTxnList[0].error?.errorCode}.  Kindly try to commit payment again.`;
			displayType = "BANNER";
		} else if (
			failedTxnList[0].statusCode === 406 &&
			failedTxnList[0]?.error?.errorCode === "API3_ERROR_PAYMENTS"
		) {
			message =
				"This Product Partner is disabled in BIP (API 3.0). Kindly contact your Bayad Admin/Account Officer for assistance.";
			displayType = "MODAL";
			header = "Product Partner is Disabled";
		} else if (
			failedTxnList[0].statusCode === 400 &&
			failedTxnList[0]?.error?.errorCode === "GENERIC_ERROR"
		) {
			message =
				"Error 400: Commit Payment - We encountered a problem while processing your payment. Kindly try to commit payment again.";
			displayType = "BANNER";
		} else if (
			failedTxnList[0].statusCode === 500 &&
			failedTxnList[0]?.error?.errorCode === "INQUIRE_EXCEPTION"
		) {
			message =
				"Error 500: Commit Payment - A timeout occurred during inquire payment (API 3.0).  Kindly try to commit payment again.";
			displayType = "BANNER";
		} else if (
			failedTxnList[0].statusCode === 504 &&
			failedTxnList[0]?.retryError
		) {
			message =
				"Error 504: Commit Payment - The max. tries for inquire payment (API 3.0) has been reached.  Kindly try to commit payment again.";
			displayType = "BANNER";
		}

		dispatch(
			setPaymentErrorCode({
				code: "TXN_ERR",
				message,
				displayType,
				header,
			})
		);
		throw { message: "Transaction Error!" };
	} else if (commitPaymentData?.errorCode === 500) {
		dispatch(
			setPaymentErrorCode({
				code: "TXN_ERR",
				message:
					"We encountered a problem while processing your payment. Kindly retry to commit payment in a few minutes.",
			})
		);
		throw { message: "Transaction Error!" };
	} else if (commitPaymentData?.errorCode === 406) {
		dispatch(
			setPaymentErrorCode({
				code: "TXN_PRODUCT_ERROR",
				message: `${PRODUCT_PARTNER_DISABLED_MESSAGE}`,
			})
		);
		throw { message: "Transaction Error!" };
	} else {
		console.log("insisde final success message...");
		dispatch(
			setSuccessMessage(
				`${successTxnLength} out of ${requestArray.length} transactions are successful.`
			)
		);
	}

	const sessionTransactionDetails =
		getSessionStorage("transactionDetails") || [];
	!sessionTransactionDetails
		? setSessionStorage("transactionDetails", txnList)
		: setSessionStorage("transactionDetails", [
				...sessionTransactionDetails,
				...txnList,
		  ]);
	return { data: { txnList, checkList } };
};

function createPaymentActionCreator(paymentFields) {
	return {
		types: [prep.type, load.type, error.type],
		promise: createPayment,
		paymentFields,
	};
}

export default transactionBuyLoad.reducer;

export {
	createPaymentActionCreator,
	toggleTransactionSummary,
	updateSelectedForPrint,
	updateTransactionList,
	updateCheckList,
	showOrHideTransactionSummary,
	togglePrintBillReceipt,
	showPrintReceiptModal,
	disablePrintButton,
	printCheckFlowModal,
	printCheckStatus,
	setPaymentErrorCode,
	setCheckDuplicate,
	setDuplicateTransactionList,
	setProcessingPaymentMessage,
	setSuccessMessage,
	setRefreshList,
};

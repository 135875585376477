import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import dateformat from "dateformat";
import styles from "./PrintReceipt.module.css";
import { makeStyles } from "@material-ui/core/styles";
import NumberFormatter from "../common/Format/NumberFormatter";
import { BranchUtil } from "../../utils/checkSession";

const useStyles = makeStyles({
	root: {
		fontSize: "11px",
		fontFamily: "Monaco",
		fontWeight: 700,
	},
	space: {
		fontSize: "11px",
		display: "inline-block",
		marginTop: "10px",
		fontFamily: "Monaco",
		fontWeight: 700,
	},
});

export const formatNumber = (num) =>
	new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(num);

const PrintReceiptSoaBuyLoad = (props, ref) => {
	const showPrintReceipt = useSelector(
		(state) => state.transactionBuyLoad.showPrintBillReceipt
	);
	const selectedForPrint = useSelector((state) =>
		state.transactionBuyLoad.selectedForPrint != null
			? state.transactionBuyLoad.selectedForPrint.values
			: null
	);

	const tpaId = useSelector((state) => state.userInfo.terminal.name);
	const branchName = BranchUtil.get()[0].name;

	const classes = useStyles();

	return (
		<div className="printme" ref={ref}>
			<Grid container>
				<Grid>
					{selectedForPrint && (
						<Grid container className="modal-body">
							<Grid className={styles.positionSOATop}>
								<span className={classes.root}>
									<b>{selectedForPrint.billerName}</b>
								</span>
								<span className={classes.root}>
									{" "}
									TRANS NO.{" "}
									{selectedForPrint.transactionNumber}
								</span>
								{selectedForPrint.reprintCount !==
									undefined && (
									<span
										className={classes.root}
										style={{ marginLeft: "2em" }}
									>
										({selectedForPrint.reprintCount + 1})
									</span>
								)}
								<br />
								<span className={classes.root}>
									{" "}
									{dateformat(
										selectedForPrint.createdAt,
										"mm/dd/yyyy h:MM:ss TT"
									)}
								</span>
								<br />
								<span className={classes.root}>
									{" "}
									<b>Teller:</b> {selectedForPrint.username}
								</span>
								<span className={classes.root}> {tpaId}</span>
								<span className={classes.root}>
									{" "}
									<b>Receipt No:</b>{" "}
									{selectedForPrint.receiptNo}
								</span>
								<br />
								<span className={classes.root}>
									{" "}
									<b>Acc No.:</b> {selectedForPrint.accountNo}
								</span>
								<br />
								{selectedForPrint.paymentMethod == "CASH" && (
									<span className={classes.root}>
										{" "}
										<b>Cash:</b> P{selectedForPrint.amount}{" "}
										<b>Check: </b> P0.00{" "}
									</span>
								)}
								{selectedForPrint.paymentMethod == "CHECK" && (
									<span className={classes.root}>
										{" "}
										<b>Cash:</b> P0.00 <b>Check: </b>P
										{selectedForPrint.amount}
									</span>
								)}
								<span className={classes.root}>
									{" "}
									<b>Fee:</b> P
									<NumberFormatter
										value={selectedForPrint.fee}
									/>
								</span>
								<span className={classes.root}>
									{" "}
									<b>Total:</b> P
									{formatNumber(
										parseFloat(selectedForPrint.amount) +
											parseFloat(selectedForPrint.fee)
									)}
								</span>
								<br />
								<span className={classes.space}>
									{" "}
									THANK YOU FOR PAYING AT {branchName}!
								</span>
								<br />
								<span className={classes.root}>
									{" "}
									AUTHORIZED PARTNER OF BAYAD CENTER.
								</span>
							</Grid>

							<Grid className={styles.positionSOABottom}>
								<span className={classes.root}>
									<b>{selectedForPrint.billerName}</b>{" "}
								</span>
								<span className={classes.root}>
									{" "}
									TRANS NO.{" "}
									{selectedForPrint.transactionNumber}
								</span>
								{selectedForPrint.reprintCount !==
									undefined && (
									<span
										className={classes.root}
										style={{ marginLeft: "2em" }}
									>
										({selectedForPrint.reprintCount + 1})
									</span>
								)}
								<br />
								<span className={classes.root}>
									{" "}
									{dateformat(
										selectedForPrint.createdAt,
										"mm/dd/yyyy h:MM:ss TT"
									)}
								</span>
								<br />
								<span className={classes.root}>
									{" "}
									<b>Teller:</b> {selectedForPrint.username}
								</span>
								<span className={classes.root}> {tpaId}</span>
								<span className={classes.root}>
									{" "}
									<b>Receipt No:</b>{" "}
									{selectedForPrint.receiptNo}
								</span>
								<br />
								<span className={classes.root}>
									{" "}
									<b>Acc No.:</b> {selectedForPrint.accountNo}
								</span>
								<br />
								{selectedForPrint.paymentMethod == "CASH" && (
									<span className={classes.root}>
										{" "}
										<b>Cash:</b> P{selectedForPrint.amount}{" "}
										<b>Check: </b> P0.00{" "}
									</span>
								)}
								{selectedForPrint.paymentMethod == "CHECK" && (
									<span className={classes.root}>
										{" "}
										<b>Cash:</b> P0.00 <b>Check: </b>P
										{selectedForPrint.amount}
									</span>
								)}
								<span className={classes.root}>
									{" "}
									<b>Fee:</b> P
									<NumberFormatter
										value={selectedForPrint.fee}
									/>
								</span>
								<span className={classes.root}>
									{" "}
									<b>Total:</b> P
									{formatNumber(
										parseFloat(selectedForPrint.amount) +
											parseFloat(selectedForPrint.fee)
									)}
								</span>
								<br />
								<span className={classes.space}>
									{" "}
									THANK YOU FOR PAYING AT {branchName}!
								</span>
								<br />
								<span className={classes.root}>
									{" "}
									AUTHORIZED PARTNER OF BAYAD CENTER.
								</span>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
		</div>
	);
};

export default React.forwardRef(PrintReceiptSoaBuyLoad);

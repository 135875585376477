import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import PartnerListPanel from "../BillerList/PartnerListPanel.js";
import BuyLoadTransactionList from "../TransactionList/BuyLoadTransactionList";

import PartnerBillSummary from "../BillSummary/PartnerBillSummary";
import TransactionSummaryBuyLoad from "../TransactionSummary/TransactionSummaryBuyLoad";
import ViewReceiptModalBuyLoad from "../ViewReceipt/ViewReceiptModalBuyLoad";

import UnablePrintReceiptModalBuyLoad from "../UnablePrintReceipt/UnablePrintReceiptModalBuyLoad";
import PrintReceiptFlowBuyLoad from "../PrintReceiptFlow/PrintReceiptFlowBuyLoad";

import { updateWallet } from "../../redux/modules/walletBalance.ts";
import HTTP from "../../helpers/ApiClient";
import SubscribeWalletBalance from "./SubscribeWalletBalance";
import { Box } from "@mui/material";

const BuyLoad = ({ showTransactionList, updateWallet }) => {
	const [walletId, setWalletId] = useState("");

	useEffect(() => {
		(async () => {
			const { data } = await HTTP.get(`v2/wallet/balance`);
			updateWallet(data.data.data);
			setWalletId(data.data.data.wallet_identifier);
		})();
	}, []);

	return (
		<>
			{walletId && (
				<SubscribeWalletBalance
					walletId={walletId}
					updateWallet={updateWallet}
				/>
			)}
			<PrintReceiptFlowBuyLoad />
			<ViewReceiptModalBuyLoad />
			<UnablePrintReceiptModalBuyLoad />
			<Box
				sx={{
					display: "grid",
					gridTemplateColumns: "1fr min(120rem, 100%) 1fr",
				}}
			>
				<Box sx={{ gridColumn: "2" }}>
					<Box
						display="grid"
						gridTemplateColumns="minmax(320px,400px) minmax(300px,1fr) minmax(320px,400px)"
					>
						<Paper
							style={{
								gridColumn: "1 / 4",
								gridRow: "1 / 3 ",
							}}
						/>
						{showTransactionList && (
							<Box
								style={{
									gridColumn: "1 / 4",
									gridRow: "1 / 3 ",
								}}
							>
								<TransactionSummaryBuyLoad />
							</Box>
						)}
						{!showTransactionList && (
							<Box
								style={{
									gridColumn: "1 / 2",
									gridRow: "1 / 3 ",
									margin: "20px 0px 20px 20px",
								}}
							>
								{!showTransactionList && <PartnerListPanel />}
							</Box>
						)}

						{!showTransactionList && (
							<Box
								span={2}
								style={{
									gridColumn: "2 / 4",
									gridRow: "1 / 3 ",
									margin: "30px 20px 20px 20px",
								}}
							>
								<BuyLoadTransactionList />
							</Box>
						)}

						<Box
							style={{
								gridColumn: "4 / 5",
								gridRow: "1 / 3 ",
								margin: "20px 20px 20px 0px",
							}}
						>
							<PartnerBillSummary />
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default connect(
	(state) => ({
		showTransactionList: state.transactionBuyLoad.showTransactionList,
	}),
	{ updateWallet }
)(BuyLoad);

import userInfo from "./userInfo";
import billerList from "./billerList";
import validation from "./validation";
import validationBuyLoad from "./validationBuyLoad";
import transaction from "./transaction";
import receipt from "./receipt";
import sessionReports from "./sessionReports";
import reprint from "./reprint";
import timeout from "./timeout";
import dialog from "./dialog";
import banks from "./banks";
import branchReport from "./branchReport";
import walletBalance from "./walletBalance.ts";
import kycInquiry from "./kycInquiry";
import kycRegistration from "./kycRegistration";
import kycRemittance from "./kycRemittance";
import kycUpdate from "./kycUpdate";
import kycBayad from "./kycBayad";
import kycPartnerList from "./kycPartnerList";
import cashoutInformation from "./cashoutInformation";
import branchAccessControl from "./branchAccessControl";
import sessionAccess from "./sessionAccess";
import latency from "./latency";
import alert from "./alert";
import partnerList from "./partnerList";
import transactionBuyLoad from "./transactionBuyLoad";

const reducers = {
	userInfo,
	billerList,
	validation,
	validationBuyLoad,
	transaction,
	receipt,
	sessionReports,
	reprint,
	timeout,
	dialog,
	banks,
	branchReport,
	walletBalance,
	kycInquiry,
	kycRegistration,
	kycRemittance,
	kycUpdate,
	kycBayad,
	kycPartnerList,
	cashoutInformation,
	branchAccessControl,
	sessionAccess,
	latency,
	alert,
	partnerList,
	transactionBuyLoad,
};

export default reducers;

import styles from "./Products.module.css";

import { Box, Paper, Stack, Typography } from "@mui/material";

import PayBillsImg from "../../assets/icons/pay-bills.png";
import BuyLoadImg from "../../assets/icons/buy-load.png";
import { Link } from "react-router-dom";

const Products = () => {
	return (
		<>
			<Box>
				<Paper
					elevation={3}
					sx={{
						height: "100vh",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						mt: "20px",
					}}
				>
					<Stack direction="row" spacing={4} justifyContent="center">
						<Link
							to="/pay-bills"
							style={{ textDecoration: "none" }}
						>
							<Stack alignItems="center">
								<img
									src={PayBillsImg}
									alt="logout"
									className={styles.productTitle}
									style={{ marginBottom: "10px" }}
								/>

								<Typography
									variant="body1"
									className={styles.productTitle}
								>
									Pay Bills
								</Typography>
							</Stack>
						</Link>

						<Link to="/buy-load" style={{ textDecoration: "none" }}>
							<Stack alignItems="center">
								<img
									src={BuyLoadImg}
									alt="buyLoad"
									className=""
									style={{ marginBottom: "10px" }}
								/>

								<Typography
									variant="body1"
									className={styles.productTitle}
								>
									Buy Load
								</Typography>
							</Stack>
						</Link>
					</Stack>
				</Paper>
			</Box>
		</>
	);
};

export default Products;

import { createSlice, current } from "@reduxjs/toolkit";
import { storeCookie, getCookie } from "../../utils/cookies";
import { formDataDEV, formDataSTG } from "../../dummyData";
import {
	getBuyLoadCategoryList,
	getBuyLoadPartnerForm,
} from "../../utils/buyLoadApi3";

const initialState = {
	initialPartnersLoaded: [],
	partners: [],
	selectedPartners: [],
	isLoadingSelectedPartner: false,
	isPartnerSuccess: false,
	selectedPartner: "",
	selectedPaymentMethod: "",
	partnerToAdd: {
		name: "",
		checkNotAllowed: false,
		multipleCheckNotAllowed: false,
		maxPartnersReached: false,
	},
	partnerTransactions: {},
	partnerOpener: {},
	isDisabledPartnerDialogOpen: false,
	isServiceFeeRetryDialogOpen: false,
};

const partnerList = createSlice({
	name: "partnerList",
	initialState,
	reducers: {
		prep: (state, payload) => {
			state.selectedPartner = payload.code;
			state.isLoadingSelectedPartner = true;
			state.isPartnerSuccess = false;
		},
		load: (state, payload) => {
			let partnerInfo = payload.result;
			console.log("partnerInfo from redux partnerList>>>", partnerInfo);
			// commented becuase static data show error for this field
			partnerInfo.logo = state.partners.find(
				(partner) => partner.code === partnerInfo.code
			).logo;
			state.partnerOpener[partnerInfo.code] = true;

			const paymentMethodParam = partnerInfo.parameters.verify.filter(
				(parameter) => Object.keys(parameter)[0] === "paymentMethod"
			)[0].paymentMethod;
			const modes = Object.keys(paymentMethodParam.rules)
				.filter((methods) => methods.includes("in:"))[0]
				.replace("in:", "")
				.split(",");

			if (state.selectedPartners.length === 0) {
				state.selectedPaymentMethod = modes[0];
				state.selectedPartners =
					state.selectedPartners.concat(partnerInfo);
				state.partnerTransactions[state.selectedPartner.code] = [
					createTransaction(
						state.selectedPartner.code + 0,
						state.selectedPartners.find(
							(partner) =>
								partner.code === state.selectedPartner.code
						).parameters.verify
					),
				];

				state.isPartnerSuccess = true;
			} else if (
				Object.keys(state.partnerTransactions).reduce(
					(accumulator, txnKey) =>
						accumulator + state.partnerTransactions[txnKey].length,
					0
				) === 30
			) {
				state.partners = state.partners.map((partner) => {
					if (partner.code === payload.result.code) {
						partner.isSelected = false;
						state.partnerToAdd.name = partner.name;
					}
					return partner;
				});
				state.partnerToAdd.maxPartnersReached = true;
				state.isPartnerSuccess = false;
			} else if (
				state.selectedPaymentMethod === "CASH" &&
				!modes.includes("CASH")
			) {
				state.partners = state.partners.map((partner) => {
					if (partner.code === payload.result.code) {
						partner.isSelected = false;
						state.partnerToAdd.name = partner.name;
					}
					return partner;
				});
				state.partnerToAdd.checkNotAllowed = true;
				state.isPartnerSuccess = false;
			} else if (state.selectedPaymentMethod === "CHECK") {
				state.partners = state.partners.map((partner) => {
					if (partner.code === payload.result.code) {
						partner.isSelected = false;
						state.partnerToAdd.name = partner.name;
					}
					return partner;
				});

				state.partnerToAdd.multipleCheckNotAllowed = true;
				state.isPartnerSuccess = false;
			} else {
				state.selectedPartners =
					state.selectedPartners.concat(partnerInfo);
				state.partnerTransactions[state.selectedPartner.code] = [
					createTransaction(
						state.selectedPartner.code + 0,
						state.selectedPartners.find(
							(partner) =>
								partner.code === state.selectedPartner.code
						).parameters.verify
					),
				];
				state.isPartnerSuccess = true;
			}
			state.isLoadingSelectedPartner = false;
			state.isPartnerSuccess = true;
		},
		setIsDisabledPartnerDialogOpen: (state, { payload }) => {
			state.isDisabledPartnerDialogOpen = payload;
		},
		listPartners: (state, action) => {
			state.partners = action.payload.partners;
			if (state.initialPartnersLoaded.length <= 0) {
				state.initialPartnersLoaded = action.payload.partners;
			}
		},
		remove: (state, { payload }) => {
			state.selectedPartners = state.selectedPartners.filter(
				(partner) => partner.code !== payload.code
			);
			state.partners = state.partners.map((partner) => {
				if (partner.code === payload.code) {
					partner.isSelected = false;
				}
				return partner;
			});
		},
		error: (state) => {
			// console.log("Error");
			state.isPartnerSuccess = false;
			state.isLoadingSelectedPartner = false;
		},
		allowCheck: (state) => {
			state.partnerToAdd = {
				name: "",
				checkNotAllowed: false,
				multipleCheckNotAllowed: false,
				maxPartnersReached: false,
			};
		},
		resetPartnerTxn: (state) => {
			state.partnerTransactions = {};
			state.selectedPartners = [];

			state.partners = state.partners.map((partner) => {
				return { ...partner, isSelected: false };
			});
		},
		resetCustomPartnerServiceFee: (state) => {
			let partnerTransactions = state.partnerTransactions;
			for (const key in partnerTransactions) {
				let code = key;
				if (isRegular(key) == false) {
					partnerTransactions[key].forEach((item, index) => {
						state.partnerTransactions[code][index]["otherCharges"] =
							"0.00";
					});
				}
			}
		},
		updatePartnerServiceFee: (state, { payload }) => {
			// console.log("payload: ", payload);
			const { partnerCode, otherCharges } = payload;
			let partnerTransactions = state.partnerTransactions;
			for (const key in partnerTransactions) {
				let code = key;
				if (code == partnerCode) {
					partnerTransactions[code].forEach((item, index) => {
						state.partnerTransactions[code][index]["otherCharges"] =
							otherCharges;
					});
				}
			}

			updateCachedServiceFee(partnerCode, otherCharges);
		},
		removePartnerTxn: (state, { payload }) => {
			state.partnerTransactions[payload.code].splice(
				payload.indexToRemove,
				1
			);
			if (state.partnerTransactions[payload.code].length <= 0) {
				const index = state.selectedPartners.findIndex(
					(partner) => partner.code === payload.code
				);
				state.selectedPartners[index] = {};
				state.selectedPartners = [...state.selectedPartners];
				state.partners = state.partners.map((partner) => {
					if (partner.code === payload.code) {
						partner.isSelected = false;
					}
					return partner;
				});
			}
		},
		addPartnerTxn: (state, { payload }) => {
			const partner = state.selectedPartners.find(
				(partner) => partner.code === payload.code
			);
			const verifyFields = partner.parameters.verify;
			const newIndex =
				parseInt(
					state.partnerTransactions[payload.code]
						.slice(-1)[0]
						.transactionKey.split(partner.code)[1]
				) + 1;
			if (
				Object.keys(state.partnerTransactions).reduce(
					(accumulator, txnKey) =>
						accumulator + state.partnerTransactions[txnKey].length,
					0
				) < 30
			) {
				state.partnerTransactions[payload.code].push(
					createTransaction(payload.code + newIndex, verifyFields)
				);
				// insert notify
			} else {
				state.partnerToAdd.name = partner.name;
				state.partnerToAdd.maxPartnersReached = true;
			}
		},
		updatePartnerTxn: (state, { payload }) => {
			console.log("payload update txn>>>>", payload);
			const index = state.partnerTransactions[payload.code].findIndex(
				(txn) => txn.transactionKey === payload.txnKey
			);
			state.partnerTransactions[payload.code][index][payload.key] =
				payload.value;
		},
		selectPaymentMethod: (state, { payload }) => {
			state.selectedPaymentMethod = payload.mode;
		},
		clearPartnerTxns: (state) => {
			Object.keys(state.partnerTransactions).forEach((code) => {
				state.partnerTransactions[code].forEach((txn, index) => {
					Object.keys(txn)
						.filter((field) => field !== "transactionKey")
						.forEach((field) => {
							state.partnerTransactions[code][index][field] = "";
						});
				});
			});
		},
		setMultipleCheckNotAllowed: (state) => {
			state.partnerToAdd.multipleCheckNotAllowed = true;
		},
		togglePartnerOpener: (state, { payload }) => {
			state.partnerOpener[payload.code] =
				!state.partnerOpener[payload.code];
		},
		toggleisLoadingSelectedPartner: (state) => {
			state.isLoadingSelectedPartner = !state.isLoadingSelectedPartner;
		},
		setProductStatus: (state, { payload }) => {
			state.productStatus = payload;
		},
		setProductPartnerStatus: (state, { payload }) => {
			state.productPartnerStatus = payload;
		},
		toggleBFAStatusOpener: (state) => {
			state.showBFAStatus = !state.showBFAStatus;
		},
		toggleServiceFeeRetryDialog: (state) => {
			console.log("CLOSE CLOSE CLOSE");
			console.log("state:", state);
			state.isServiceFeeRetryDialogOpen =
				!state.isServiceFeeRetryDialogOpen;
		},
	},
});

const {
	prep,
	load,
	listPartners,
	remove,
	error,
	allowCheck,
	removePartnerTxn,
	resetPartnerTxn,
	resetCustomPartnerServiceFee,
	updatePartnerServiceFee,
	addPartnerTxn,
	updatePartnerTxn,
	selectPaymentMethod,
	clearPartnerTxns,
	setMultipleCheckNotAllowed,
	togglePartnerOpener,
	toggleServiceFeeRetryDialog,
	toggleisLoadingSelectedPartner,
	setIsDisabledPartnerDialogOpen,
	setProductStatus,
	setProductPartnerStatus,
	toggleBFAStatusOpener,
} = partnerList.actions;

const getPartnerDetails = async (client, state) => {
	const partnerCode = state.partnerList.selectedPartner.code;

	const isServiceFeeExist = await isServiceFeeExists(partnerCode);
	const partnerFormData = await getBuyLoadPartnerForm(
		partnerCode,
		isServiceFeeExist
	);
	const loadCategoryData = await getBuyLoadCategoryList(partnerCode);
	if (partnerFormData.status == true) {
		storeServiceFee(...partnerFormData.feesData);
		if (loadCategoryData.status == true) {
			const updatedformData = JSON.parse(
				JSON.stringify(partnerFormData.formData)
			);
			updatedformData.data.parameters.verify.forEach((item) => {
				if (item["otherInfo.LoadCategory"]) {
					Object.keys(item["otherInfo.LoadCategory"].rules).forEach(
						(ruleKey) => {
							if (ruleKey.startsWith("in:")) {
								item["otherInfo.LoadCategory"].rules[
									ruleKey
								].options = loadCategoryData?.data;
							}
						}
					);
					// item["otherInfo.LoadCategory"].rules["in:0"].options =
					// 	loadCategoryData?.data;
				}
			});
			return updatedformData;
		} else {
			return partnerFormData.formData;
		}
	}
};

function isServiceFeeExists(partnerCode) {
	if (getCookie("service_fee")) {
		const list = getCookie("service_fee");
		return list.some((item) => item.code === partnerCode);
	} else {
		return false;
	}
}

function isRegular(partnerCode) {
	const list = getCookie("service_fee");
	const foundItem = list.find((item) => item.code === partnerCode);
	if (foundItem.isRegular == 1) {
		return true;
	} else {
		return false;
	}
}

function updateCachedServiceFee(partnerCode, otherCharges) {
	const midnight = new Date();
	midnight.setHours(23, 59, 59, 0);
	var list;

	// if cached list is already exist modify the list
	if (getCookie("service_fee")) {
		list = getCookie("service_fee");

		list = list.map((item) => {
			if (item.code === partnerCode) {
				return {
					...item,
					otherCharges: otherCharges, // Example update value, change as needed
					isRegular: 1, // Assuming 1 represents true/yes
					isRequestSuccessful: true,
				};
			}
			return item;
		});
	} else {
		// if cached list is not yet exist save directly
		list = [
			{
				code: partnerCode,
				otherCharges: otherCharges,
				isRegular: isRegular,
				isRequestSuccessful: true,
			},
		];
	}

	storeCookie("service_fee", list, midnight);
}

function storeServiceFee(
	partnerCode,
	otherCharges,
	isRegular,
	isRequestSuccessful
) {
	const midnight = new Date();
	midnight.setHours(23, 59, 59, 0);
	var list;

	// if cached list is already exist modify the list
	if (getCookie("service_fee")) {
		list = getCookie("service_fee");
		list.push({
			code: partnerCode,
			otherCharges: otherCharges,
			isRegular: isRegular,
			isRequestSuccessful: isRequestSuccessful,
		});
	} else {
		// if cached list is not yet exist save directly
		list = [
			{
				code: partnerCode,
				otherCharges: otherCharges,
				isRegular: isRegular,
				isRequestSuccessful: isRequestSuccessful,
			},
		];
	}

	storeCookie("service_fee", list, midnight);
}

function getServiceFee(partnerCode) {
	partnerCode = partnerCode.slice(0, -1);
	const list = getCookie("service_fee");
	const foundItem = list.find((item) => item.code === partnerCode);
	if (foundItem.isRegular == 1) {
		return foundItem.otherCharges;
	} else {
		return null;
	}
}
function partnerActionCreator(code) {
	return {
		types: [prep.type, load.type, error.type],
		promise: getPartnerDetails,
		code,
	};
}

function createTransaction(transactionCode, transactionFields) {
	return transactionFields
		.filter(
			(field) =>
				Object.keys(field)[0] !== "paymentMethod" &&
				Object.keys(field)[0] !== "otherCharges" &&
				!Object.keys(field)[0].startsWith("otherInfo.CheckDetails")
		)
		.reduce(
			(accumulator, fieldItem) => {
				return { ...accumulator, [Object.keys(fieldItem)[0]]: "" };
			},
			{
				transactionKey: transactionCode,
				otherCharges: getServiceFee(transactionCode),
			}
		);
}

export default partnerList.reducer;

export {
	partnerActionCreator,
	listPartners,
	remove,
	allowCheck,
	removePartnerTxn,
	resetPartnerTxn,
	resetCustomPartnerServiceFee,
	updatePartnerServiceFee,
	addPartnerTxn,
	updatePartnerTxn,
	selectPaymentMethod,
	clearPartnerTxns,
	setMultipleCheckNotAllowed,
	togglePartnerOpener,
	toggleServiceFeeRetryDialog,
	toggleisLoadingSelectedPartner,
	setIsDisabledPartnerDialogOpen,
	setProductStatus,
	setProductPartnerStatus,
	toggleBFAStatusOpener,
};

import api from "./api3Client";

// Base URL of your API
const API3_URL = process.env.REACT_APP_API3_ENDPOINT;

export const getBuyLoadPartnerList = async () => {
	try {
		const response = await api.get(`${API3_URL}/v3/buyload`);
		// billers response>>>>
		// const response = await HTTP.get("/v2/buyload");
		return { status: true, data: response?.data.data };
	} catch (error) {
		console.error("Error fetching users:", error);
		return { status: false, data: error };
	}
};

export const getBuyLoadPartnerForm = async (partnerCode, isServiceFeeExist) => {
	const promises = [];

	const partnerInfoPromise = api.get(`${API3_URL}/v3/buyload/${partnerCode}`);
	promises.push(partnerInfoPromise);
	let partnerServiceFeePromise = null;
	if (!isServiceFeeExist) {
		partnerServiceFeePromise = api.get(
			`${API3_URL}/v3/buyload/${partnerCode}/fees`
		);
		promises.push(partnerServiceFeePromise);
	}

	const response = await Promise.allSettled(promises);
	const storeServiceFeeData = [];
	if (isServiceFeeExist == false) {
		response[0].value.data.data["otherCharges"] =
			response[1].status === "fulfilled"
				? response[1].value.data.data.otherCharges
				: "0.00";
		let { code } = response[0].value.data.data;
		const feeResponseStatusCode = response[1].status;
		let isRegular = 0;
		let otherCharges = 0.0;
		let isRequestSuccessful;
		if (
			response[1].status !== "rejected" &&
			response[1].value.data.data &&
			response[1].value.data.data.length !== 0
		) {
			const data = response[1].value.data.data;
			if (
				data.otherCharges.trim() !== "" &&
				!isNaN(parseFloat(data.otherCharges)) &&
				isFinite(data.otherCharges)
			) {
				otherCharges = response[0].value.data.data.otherCharges;
				isRegular = 1;
				isRequestSuccessful = true;
			}
			// handler for custom billers returning otherCharges:NaN value
			else if (data.otherCharges == "NaN") {
				isRequestSuccessful = true;
			}
		}

		if (feeResponseStatusCode == "rejected") {
			const errorCode = response[1].reason.response.status;
			otherCharges = 0.0;
			isRegular = 0;
			isRequestSuccessful = errorCode == 422 ? true : false;
		}

		storeServiceFeeData.push(code);
		storeServiceFeeData.push(otherCharges);
		storeServiceFeeData.push(isRegular);
		storeServiceFeeData.push(isRequestSuccessful);
	}

	if (response[0].status === "fulfilled") {
		return {
			status: true,
			formData: response[0].value.data,
			feesData: storeServiceFeeData,
		};
	} else {
		return {
			status: false,
			formData: [],
			feesData: [],
		};
	}
};

export const getBuyLoadCategoryList = async (partnerCode) => {
	try {
		const response = await api.get(
			`${API3_URL}/v3/buyload/cws/categories?partner_id=${partnerCode}`
		);
		const categories = response?.data?.categories;
		const categoryOptions = categories.reduce((acc, category) => {
			acc[category.id] = category.name;
			return acc;
		}, {});

		const catData = {
			0: "Select Load Category",
			...categoryOptions,
		};
		// console.log("category list>>>>", catData);
		return { status: true, data: catData };
	} catch (error) {
		console.error("Error fetching users:", error);
		return { status: false, data: error };
	}
};

export const getLoadList = async (partnerCode, id) => {
	try {
		const response = await api.get(
			`${API3_URL}/v3/buyload/cws/sku?partner_id=${partnerCode}&category_id=${id}`
		);

		const promos = response?.data?.promos;
		// console.log("promos in API call>>>", promos);
		if (promos.length > 0) {
			const promoOptions = promos.reduce((acc, promo) => {
				// const key = `${promo.id}_${promo.price}`;
				const key = `${promo.price}`;
				if (promo.category_id > 0) {
					acc[key] = promo.title;
				}
				return acc;
			}, {});

			const promoData = {
				0: "Select Load Promo",
				...promoOptions,
			};

			console.log("1111 if promosData", promoData);

			return { status: true, data: promoData };
		} else {
			const promoData = {
				0: "Select Load Promo",
			};
			console.log("2222 else promosData", promoData);

			return { status: true, data: promoData };
		}
	} catch (error) {
		const promoData = {
			0: "Select Load Promo",
		};
		console.error("Error fetching users:", error);
		return { status: false, data: promoData };
	}
};

import React from "react";
import { useSelector } from "react-redux";
import styles from "./PrintReceipt.module.css";
import RecieptCheckBuyLoad from "./RecieptCheckBuyLoad";
import { PropaneSharp } from "@mui/icons-material";
import { getSessionStorage } from "../../utils/storage";
import _ from "lodash";

const PrintRecieptCheckBuyLoad = (_, ref, props) => {
	const selectedForPrint = useSelector((state) =>
		state.transactionBuyLoad.selectedForPrint != null
			? state.transactionBuyLoad.selectedForPrint.values
			: null
	);

	let transactionList = null;
	const sessionTransactionDetails =
		getSessionStorage("transactionDetails") || [];
	if (
		selectedForPrint &&
		sessionTransactionDetails &&
		sessionTransactionDetails.length > 0
	) {
		transactionList = sessionTransactionDetails.filter(
			(t) => t.checkReceiptNo === selectedForPrint.checkReceiptNo
		);
	}

	const tpaId = useSelector((state) => state.userInfo.terminal.name);
	return (
		<div className="printme" style={{ padding: "0" }} ref={ref}>
			<div style={{ marginLeft: "0.35cm", marginTop: "0.7cm" }}>
				<RecieptCheckBuyLoad
					selectedForPrint={selectedForPrint}
					transactionList={transactionList}
					tpaId={tpaId}
				/>
			</div>
		</div>
	);
};

export default React.forwardRef(PrintRecieptCheckBuyLoad);

import styles from "./App.module.css";
import logo from "../../assets/logo/logo-bayadFA-white.svg";
import failedIcon from "../../assets/icons/ic-failed.svg";
import helpIcon from "../../assets/icons/ic-help.svg";
import logoutIcon from "../../assets/icons/ic-logout.svg";
import logoutIcon2 from "../../assets/icons/ic-logout2.svg";

import {
	AppBar,
	Button,
	Toolbar,
	Link,
	Dialog,
	DialogContent,
	DialogActions,
} from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { Component, useState } from "react";
import {
	BrowserRouter as Router,
	NavLink,
	Route,
	Switch,
	useRouteMatch,
	useLocation,
} from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Account from "../Account/Account";
import {
	NotificationSupervisor,
	NotificationTeller,
} from "../Notifications/Notifications";
import PayBills from "../PayBills/PayBills";
import CashManagement from "../CashManagement/CashManagement";
import Remittance from "../Remittance/Remittance";
import Transactions from "../Transaction/Transactions.js";
import BayadId from "../BayadId/BayadId.js";
import UserManagement from "../UserManagement/UserManagement";
import Help from "../Help/Help.js";
import cx from "classnames";
import ChangeFunds from "../ChangeFunds/ChangeFunds";
import Logout from "../Logout/Logout";
import { HistoryListener } from "react-router-navigation-confirm";
import withStyles from "@material-ui/core/styles/withStyles";
import HTTP from "../../helpers/ApiClient";
import SessionTimeoutModal from "../SessionTimeout/SessionTimeoutModal";
import SessionExpiredValidateModal from "../SessionTimeout/SessionExpiredValidateModal";
import TransactionDisconnectedAccountModal from "../TransactionList/TransactionDisconnectedModal";
import AccessDeniedDialog from "../Dialog/Dialog";
import {
	toggleUserLoggingOut,
	toggleModal,
	toggleUnlockReload,
	toggleShowValidateExpiryModal,
} from "../../redux/modules/timeout";
import { hideInvalidIpModal } from "../../redux/modules/userInfo";
import { hideDialog } from "../../redux/modules/dialog";
import { connect } from "react-redux";
import {
	UserInfoUtil,
	getScopes,
	TokenUtil,
	isReprintRequestScope,
	isReprintCheckRequestScope,
} from "../../utils/checkSession";
import { clearSessionStorage, getSessionStorage } from "../../utils/storage";
import { clearErrorModalMessage } from "../../redux/modules/validation";
import { checkIfSupervisor } from "../../utils/checkSession";
import ReminderModal from "../Reminder/Reminder";
import NotificationTellerBackground from "../Notifications/NotificationTellerBackground";
import TransactionsHistory from "../TransactionsHistory/TransactionsHistory";
import SessionAccessMonitor from "./SessionAccessMonitor";
import ConnectivityStatus from "../Connectivity/ConnectivityStatus.jsx";
import ConnectivityAlert from "../Connectivity/ConnectivityAlert";
// import { pdfjs } from "react-pdf";
// import pdfjsWorker from "react-pdf/node_modules/pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

import DoneIcon from "@mui/icons-material/Done";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Products from "../Products/Products";
import BuyLoad from "../BuyLoad/BuyLoad";

export const GreenColorButton = withStyles(() => ({
	root: {
		width: "126px",
		height: "42px",
		color: "#FFFFFF",
		backgroundColor: "#32AA4D",
		"&:hover": {
			backgroundColor: "#138906",
		},
	},
}))(Button);

export const WhiteColorButton = withStyles(() => ({
	root: {
		width: "126px",
		height: "42px",
		color: "#4B5051",
		backgroundColor: "#FFFFFF",
		border: "1px solid #CED4DA",
		"&:hover": {
			backgroundColor: "#d2d1d1",
		},
	},
}))(Button);

export const GrayColorButton = withStyles(() => ({
	root: {
		width: "126px",
		height: "42px",
		color: "#4B5051",
		backgroundColor: "#CED4DA",
		border: "1px solid #CED4DA",
		"&:hover": {
			backgroundColor: "#b0b4b7",
		},
	},
}))(Button);

export const getCurrentDate = () => {
	const currentDate = new Date();
	const currentDayOfMonth = currentDate.getDate();
	const currentMonth = currentDate.getMonth();
	const currentYear = currentDate.getFullYear();
	const currentTime = currentDate.toString().slice(16, 24);
	const dateString =
		currentYear +
		"-" +
		(currentMonth + 1) +
		"-" +
		currentDayOfMonth +
		" " +
		currentTime;
	return dateString;
};

class App extends Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
		this.state = {
			isLogOutOpen: false,
			isSupervisor: false,
		};
	}

	componentDidMount() {
		(async () => {
			const supervisorPermissions = [
				"bfa.login.request.pending",
				"bfa.login.request.history",
				"bfa.user.list",
				"bfa.branch.access.list",
			];
			const data = await getScopes();
			if (
				data &&
				supervisorPermissions.some((e) =>
					data.data.find((v) => v.scope === e)
				)
			) {
				this.setState({ isSupervisor: true });
			}
		})();
	}

	handleLogOut = () => {
		this.props.toggleUserLoggingOut();
		this.setState({ isLogOutOpen: true });
	};

	handleCancel = () => {
		this.props.toggleUserLoggingOut();
		this.setState({ isLogOutOpen: false });
	};

	handleConfirm = () => {
		const userDetails = UserInfoUtil.get();

		let body = {
			user_id: userDetails.uuid,
			action: "No Session Logout",
			owner_id: userDetails.roles[0].platform.id,
			owner_type: userDetails.uamRole,
			created_at: getCurrentDate(),
			updated_at: getCurrentDate(),
			new_values: "{}",
			old_values: "{}",
			source_ip: "",
		};

		HTTP.post("/v2/user/logout", body)
			.then(() => {
				clearSessionStorage();
				window.location.reload();
				window.location.replace("/");
			})
			.catch((error) => {
				console.log(error);
			});
	};

	render() {
		const stringToken = JSON.stringify({ token: TokenUtil.get() });
		const objectToken = JSON.parse(stringToken);
		const buyLoadBaseUrl = process.env.REACT_APP_BUY_LOAD_URL;
		const buyPromoBaseUrl = process.env.REACT_APP_BUY_PROMO_URL;
		const buyLoadUrl = buyLoadBaseUrl + "&token=" + objectToken.token;
		const buyPromoUrl = buyPromoBaseUrl + "&token=" + objectToken.token;

		const routes = [
			{
				title: "Products & Services",
				shortcut: "SHFT + F1",
				to: "/products",
				key: "products",
				subMenu: [
					{
						title: "Select Products & Services",
						shortcut: "",
						to: "/",
						key: "select-producst-services",
					},
					{
						title: "Pay Bills",
						shortcut: "",
						to: "/pay-bills",
						key: "payBills",
					},
					{
						title: "Buy Load",
						shortcut: "",
						to: "/buy-load",
						key: "buyLoad",
					},
				],
			},
			// {
			// 	title: "Pay Bills",
			// 	shortcut: "SHFT + F1",
			// 	to: "/pay-bills",
			// 	key: "payBills",
			// },
			// {
			// 	title: "Buy Load",
			// 	shortcut: "",
			// 	to: buyLoadUrl,
			// 	key: "buy-load",
			// },
			// {
			// 	title: "Buy Promo",
			// 	shortcut: "",
			// 	to: buyPromoUrl,
			// 	key: "buy-promo",
			// },
			// {
			// 	title: "Buy Load v2",
			// 	shortcut: "",
			// 	to: "/buy-load-v2",
			// 	key: "buy-load-v2",
			// },
			{
				title: "Cash Management",
				shortcut: "SHFT + F2",
				to: "/cash-management",
				key: "cash-management",
			},
			// {
			// 	title: "Cash Out",
			// 	shortcut: "SHFT + F3",
			// 	to: "/cash-out",
			// 	key: "cash-out",
			// },
			{
				title: "Transactions History",
				shortcut: "SHFT + F4",
				to: "/transactions",
				key: "transactions",
			},
			// {
			// 	title: "Bayad ID",
			// 	shortcut: "SHFT + F5",
			// 	to: "/bayad-id",
			// 	key: "bayad-id",
			// },
			{
				title: "User Management",
				shortcut: "",
				to: "/user-management",
				key: "user-management",
			},
		];

		return (
			<>
				<ConnectivityAlert />
				<SessionAccessMonitor />
				{this.props.showModal && (
					<SessionTimeoutModal
						toggleModal={this.props.toggleModal}
						toggleUnlockReload={this.props.toggleUnlockReload}
					/>
				)}

				{this.props.errorModalMessage && (
					<TransactionDisconnectedAccountModal
						msg={this.props.errorModalMessage}
						clearError={this.props.clearErrorModalMessage}
						codemsg={this.props.codeIdentifier}
					/>
				)}

				{this.props.showReminderModal && <ReminderModal />}

				<AccessDeniedDialog
					imgSrc={failedIcon}
					title="Access Denied!"
					message={
						<>
							Your IP Address is not registered.
							<br />
							Kindly coordinate with your supervisor for
							<br />
							the registration of your IP Address.
						</>
					}
					open={this.props.showInvalidIpModal}
					toggleDialog={this.props.hideInvalidIpModal}
				/>

				<AccessDeniedDialog
					title={"Access Denied"}
					message={
						"Sorry, you don't have access to this function. Please contact your Admin to grant access permissions. Thanks!"
					}
					open={this.props.showDialog}
					toggleDialog={this.props.hideDialog}
					imgSrc={failedIcon}
				/>

				<Router>
					<div style={{ height: "inherit" }}>
						<CustomAppBar
							routes={routes}
							handleLogOut={this.handleLogOut}
							isSupervisor={this.state.isSupervisor}
							scheduleStatus={this.props.scheduleStatus}
						/>
						<div style={{ marginTop: "10px", height: "inherit" }}>
							<Switch>
								<HistoryListener>
									<Route exact path="/">
										<ChangeFunds />
									</Route>
									<Route exact path="/pay-bills">
										<PayBills />
									</Route>
									<Route exact path="/cash-management">
										<CashManagement />
									</Route>
									<Route exact path="/products">
										<Products />
									</Route>
									<Route exact path="/buy-load">
										<BuyLoad />
									</Route>
									<Route exact path="/cash-out">
										<Remittance />
									</Route>
									<Route exact path="/transactions">
										<TransactionsHistory />
									</Route>
									<Route exact path="/bayad-id">
										<BayadId />
									</Route>
									<Route exact path="/user-management">
										<UserManagement />
									</Route>
									<Route exact path="/help">
										<Help />
									</Route>
								</HistoryListener>
							</Switch>
						</div>

						<LogoutModal
							open={this.state.isLogOutOpen}
							handleCancel={this.handleCancel}
							handleConfirm={this.handleConfirm}
						/>
					</div>
				</Router>
			</>
		);
	}
}

function LogoutModal({ open, handleCancel, handleConfirm }) {
	return (
		<Dialog
			open={open}
			disableBackdropClick
			aria-labelledby="customized-dialog-title"
		>
			<DialogContent className={styles.dialogContent}>
				<img
					src={logoutIcon2}
					alt="logout"
					className={styles.logoutImg}
				/>
				<Typography
					className={styles.textTitle}
					style={{ margin: "10px" }}
				>
					Logout
				</Typography>
				<Typography className={styles.textNormal}>
					Are you sure you want to logout?
				</Typography>
			</DialogContent>
			<DialogContent dividers>
				<DialogActions>
					<WhiteColorButton onClick={() => handleCancel()}>
						Cancel
					</WhiteColorButton>
					<GreenColorButton onClick={() => handleConfirm()}>
						Confirm
					</GreenColorButton>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
}

function CustomAppBar({ routes, handleLogOut, isSupervisor, scheduleStatus }) {
	let withSession = getSessionStorage("withSession");
	return (
		<>
			<AppBar className={styles.appBar} position={"fixed"}>
				<Toolbar className={styles.toolBar}>
					<Box
						sx={{
							display: "grid",
							alignItems: "center",
							gridTemplateColumns: "1fr min(119rem, 100%) 1fr",
							width: "100%",
						}}
					>
						<Box display={"grid"} gridColumn={"2/3"}>
							<Box
								display={"grid"}
								gridTemplateColumns={"auto auto 850px"}
								gap="0"
							>
								<Box
									style={{
										gridColumn: "1 / 2",
										gridRow: "1 / -1 ",
									}}
								>
									<img
										style={{
											marginTop: "6px",
										}}
										src={logo}
										alt="bfa-white-logo"
									/>
								</Box>
								<Box
									style={{
										gridColumn: "2 / 3",
										gridRow: "1 / -1 ",
									}}
								>
									<Box
										display={"flex"}
										flexDirection={"row"}
										justifyContent={"flex-start"}
										alignItems={"center"}
										style={{ marginRight: 0, flexGrow: 1 }}
										width="100%"
									>
										{routes.map((val) => (
											<ActiveLink
												key={val.key}
												isSupervisor={isSupervisor}
												scheduleStatus={scheduleStatus}
												routesCount={routes.length}
												{...val}
											/>
										))}
									</Box>
								</Box>
								<Box
									style={{
										gridColumn: "3 / 4",
										gridRow: "1 / -1 ",
									}}
								>
									<Box
										display={"flex"}
										flexDirection={"row"}
										alignItems="center"
										justifyContent={"flex-end"}
										gap={"17px"}
										style={{
											marginTop: "3px",
											flexShrink: 0,
										}}
									>
										{checkIfSupervisor() ? (
											<NotificationSupervisor />
										) : (
											<NotificationTeller />
										)}
										<Account />
										{(isReprintRequestScope() ||
											isReprintCheckRequestScope()) && (
											<NotificationTellerBackground />
										)}
										<Box
											display="flex"
											flexDirection="column"
											alignItems="center"
											justifyContent="center"
										>
											<Link
												to="/help"
												component={NavLink}
												style={{
													textDecoration: "none",
													color: "white",
													display: "flex",
													alignItems: "center",
												}}
											>
												<img
													src={helpIcon}
													alt="help"
													style={{
														margin: "auto",
													}}
												/>

												<Typography
													className={styles.textTitle}
												>
													&nbsp;Help
												</Typography>
											</Link>
										</Box>
										<ConnectivityStatus />

										{withSession === null ||
										withSession == 0 ? (
											<Button
												display="flex"
												flexDirection="row"
												alignItems="center"
												className={styles.logOutButton}
												onClick={() => handleLogOut()}
											>
												<img
													src={logoutIcon}
													alt="logout"
													style={{
														margin: "auto",
													}}
												/>
												<span
													className={
														styles.logOutButton
													}
												>
													&nbsp;Logout
												</span>
											</Button>
										) : (
											<Logout />
										)}
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
			<Toolbar className={styles.toolBar} />
		</>
	);
}

function ActiveLink({
	to,
	shortcut,
	title,
	subMenu,
	isSupervisor,
	scheduleStatus,
	routesCount,
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const open = Boolean(anchorEl);

	const location = useLocation();

	const handleClickListItem = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	// greather than or equal to
	var screenWidth;
	if (routesCount == 4) screenWidth = "1615px";
	if (routesCount == 3) screenWidth = "1532px";

	const showShorcut = useMediaQuery(`(min-width:${screenWidth})`);

	let toRoute = subMenu
		? subMenu.some((menu) => location.pathname === menu.to)
		: to;

	let match = useRouteMatch({
		path: toRoute,
		exact: true,
	});

	// console.log("chesco link: ", to);

	let withSession = getSessionStorage("withSession");

	// Hide paybills when user has no session and
	if (
		(withSession === null || withSession == 0) &&
		["/", "/pay-bills"].includes(to)
	) {
		return null;
	} else if (!isSupervisor && "/user-management" === to) {
		return null;
	}

	// Check if the link is external
	const isExternalLink = to.startsWith("http");

	return (
		<Grid item>
			{isExternalLink ? (
				// External links use <a> for proper redirection
				<a
					href={to}
					target="_blank"
					rel="noopener noreferrer"
					style={{
						textDecoration: "none",
					}}
				>
					<Button
						className={styles.appBarButton}
						classes={{ disabled: styles.appbarBtnDisabled }}
						disabled={false} // You can add conditions if necessary
					>
						<div className={styles.textTitle}>{title}</div>
						{showShorcut && (
							<div className={styles.textSmall}>
								{shortcut !== "" ? "| " + shortcut : null}
							</div>
						)}
					</Button>
				</a>
			) : subMenu ? (
				<>
					<Button
						className={
							toRoute
								? cx(styles.appBarButton, styles.btnActive)
								: styles.appBarButton
						}
						classes={{ disabled: styles.appbarBtnDisabled }}
						disabled={
							scheduleStatus === "CLOSED" &&
							to !== "/cash-management" &&
							to !== "/transactions"
						}
						id="lock-button"
						aria-haspopup="listbox"
						aria-controls="lock-menu"
						aria-label="when device is locked"
						aria-expanded={open ? "true" : undefined}
						onClick={handleClickListItem}
					>
						<div className={styles.textTitle}>
							<span className={styles.titleText}>
								{title}
								{showShorcut && (
									<div
										className={styles.textSmall}
										style={{
											marginLeft: "8px",
											whiteSpace: "nowrap",
										}}
									>
										{shortcut !== ""
											? "| " + shortcut
											: null}
									</div>
								)}
								<KeyboardArrowDownIcon
									className={showShorcut ? styles.icon : ""}
								/>
							</span>
						</div>
					</Button>
					<Menu
						id="lock-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							"aria-labelledby": "lock-button",
							role: "listbox",
						}}
					>
						{subMenu.map((menu, index) => (
							<MenuItem
								component={NavLink}
								to={menu.to}
								key={menu.title}
								disabled={index === 0}
								selected={index === selectedIndex}
								onClick={(event) =>
									handleMenuItemClick(event, index)
								}
								sx={{
									"&.Mui-selected": {
										backgroundColor: "#ffffff",
										color: "#F26122", // Change background color when selected
									},
									"&.Mui-selected:hover": {
										backgroundColor: "#ffffff",
										color: "#F26122",
									},
									"&:hover": {
										backgroundColor: "#F6F5F7",
										color: "#F26122",
									},
									"& .MuiListItemIcon-root": {
										color: "#ffffff",
									},
									"&.Mui-selected .MuiListItemIcon-root": {
										color: "#F26122", // Change icon color when selected
									},
									"&:hover .MuiListItemIcon-root": {
										backgroundColor: "#F6F5F7",
										color: "#F26122",
									},
								}}
							>
								<ListItemText
									sx={{
										"& .MuiTypography-root": {
											fontFamily:
												"'Poppins-Regular', sans-serif",
										},
									}}
								>
									{menu.title}
								</ListItemText>
								<ListItemIcon>
									<DoneIcon
										fontSize="small"
										sx={{
											fontFamily:
												"'Poppins-Regular', sans-serif",
										}}
									/>
								</ListItemIcon>
							</MenuItem>
						))}
					</Menu>
				</>
			) : (
				<>
					<Button
						className={
							match
								? cx(styles.appBarButton, styles.btnActive)
								: styles.appBarButton
						}
						component={NavLink}
						to={to}
						classes={{ disabled: styles.appbarBtnDisabled }}
						disabled={
							scheduleStatus === "CLOSED" &&
							to !== "/cash-management" &&
							to !== "/transactions"
						}
					>
						<div className={styles.textTitle}>{title}</div>
						{showShorcut && (
							<div
								className={styles.textSmall}
								style={{
									marginLeft: "8px",
									whiteSpace: "nowrap",
								}}
							>
								{shortcut !== "" ? "| " + shortcut : null}
							</div>
						)}
					</Button>
				</>
			)}
		</Grid>
	);
}

export default connect(
	(state) => ({
		showInvalidIpModal: state.userInfo.showInvalidIpModal,
		scheduleStatus: state.timeout.schedule.status,
		showReminderModal: state.timeout.showReminderModal,
		showModal: state.timeout.showModal,
		showDialog: state.dialog.showModal,
		showValidateExpiryModal: state.timeout.showValidateExpiryModal,
		errorModalMessage: state.validation.errorModalMessage,
		codeIdentifier: state.validation.codeIdentifier,
	}),
	{
		hideInvalidIpModal,
		toggleUserLoggingOut,
		toggleModal,
		toggleUnlockReload,
		hideDialog,
		toggleShowValidateExpiryModal,
		clearErrorModalMessage,
	}
)(App);

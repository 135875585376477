import { setIsWalletSufficient, setShowAlert } from "./walletBalance";
import { createSlice } from "@reduxjs/toolkit";
import { updatePartnerTxn } from "./partnerList";
import { retry, retry_f } from "../../utils/client";
import _, { isEmpty, unset } from "lodash";
import dot from "dot-object";
import moment from "moment";
import errorCodes, { findErrorMessage } from "../../constants/errorCodes";
import { setIsDisabledPartnerDialogOpen } from "./partnerList";
import { BranchUtil, TerminalUtil } from "../../utils/checkSession";
import { getCookie } from "../../utils/cookies";
import { removeSpecialCharAndSpace } from "../../utils/utils";
import api from "../../utils/api3Client";
const API3_URL = process.env.REACT_APP_API3_ENDPOINT;

export const timeoutErrorCodes = [408, 502, 504];

const initialState = {
	successValidation: {},
	validTransactions: {},
	failedTransactions: {},
	isValidating: false,
	timeoutError: {
		code: null,
		statusCode: null,
		message: "",
	},
	validateMessageStatus: null,
	verifyFields: {},
	isValidated: false,
	paymentDetails: {},
	isGenericError: false,
	notActiveError: "",
	billSummaryFields: {
		billCount: "0",
		totalBillDue: "0.00",
		totalConvenienceFee: "0.00",
		totalBankCharge: "0.00",
		totalChannelFee: "0.00",
		totalAmountDue: "0.00",
		cashReceived: "0.00",
		checkReceived: "0.00",
		cashDue: "0.00",
		totalReceived: "0.00",
		change: "0.00",
		originalTotalReceived: "0.00",
		additionalCash: "",
		convenienceFee: "",
	},
	errorModalMessage: undefined,
	codeIdentifier: undefined,
	currentWithDeduction: true,
	currentTxnGroupNo: null,
	currentPartnerValidations: null,
};
const PARTNER_DISABLED_MESSAGE =
	"Access Denied - This Product Partner is currently disabled. Please contact Bayad Helpdesk or your Bayad admin support to enable this product partner in the BIP Portal.";
const validationBuyLoad = createSlice({
	name: "validationBuyLoad",
	initialState,
	reducers: {
		prep: (state) => {
			state.isValidating = true;
			state.timeoutError = {
				code: null,
				statusCode: null,
				message: "",
			};
			state.validateMessageStatus = null;
		},
		load: (state, payload) => {
			console.log("validate payload>>", payload);
			state.validTransactions = {};
			state.failedTransactions = {};
			let totalBillDue = 0.0;
			let totalConvenienceFee = 0.0;
			let billCount = 0;
			for (const code of Object.keys(
				payload.result.transactionsValidated
			)) {
				for (const txn of payload.result.transactionsValidated[code]) {
					if (txn.valid) {
						state.validTransactions[code]
							? state.validTransactions[code].push(txn)
							: (state.validTransactions[code] = [txn]);
					} else {
						if (txn.errors.general) {
							console.log("General error: ", txn.errors.general);
							state.isGenericError = true;
						}
						for (const field in txn.errors) {
							const { isProductTypeError, isWalletBalanceError } =
								validateErrors(txn.errors[field]);
							if (!isProductTypeError && isWalletBalanceError) {
								txn.errors[field] = txn.errors[field].filter(
									(error) =>
										error.code !== 17 ||
										error.code !==
											"CHANNEL_BALANCE_INSUFFICIENT"
								);
							}
						}
						state.failedTransactions[code]
							? state.failedTransactions[code].push(txn)
							: (state.failedTransactions[code] = [txn]);
					}
				}
			}

			for (const txn of payload.result.requestArray) {
				billCount += 1;
				totalBillDue =
					totalBillDue + (txn.amount ? parseFloat(txn.amount) : 0.0);
				totalConvenienceFee =
					totalConvenienceFee +
					(txn.otherCharges ? parseFloat(txn.otherCharges) : 0.0);
			}
			state.isValidating = false;
			state.isValidated = true;
			let totalBankCharge = 0.0;
			let totalChannelFee = 0.0;
			let checkReceived =
				state.paymentDetails.otherInfo &&
				state.paymentDetails.otherInfo.CheckDetails &&
				state.paymentDetails.otherInfo.CheckDetails.Amount
					? parseFloat(
							state.paymentDetails.otherInfo.CheckDetails.Amount
					  )
					: parseFloat("0.00");
			let cashReceived =
				state.paymentDetails.otherInfo &&
				state.paymentDetails.otherInfo.initialCash
					? parseFloat(state.paymentDetails.otherInfo.initialCash)
					: parseFloat("0.00");
			let totalReceived = cashReceived + checkReceived;
			let totalAmountDue =
				totalBillDue +
				totalConvenienceFee +
				totalBankCharge +
				totalChannelFee;
			let cashDue = totalAmountDue - totalReceived;
			let change = totalReceived - totalAmountDue;

			state.billSummaryFields = {
				billCount: billCount,
				totalBillDue: totalBillDue.toFixed(2),
				totalConvenienceFee: totalConvenienceFee.toFixed(2),
				totalBankCharge: totalBankCharge.toFixed(2),
				totalChannelFee: totalChannelFee.toFixed(2),
				cashReceived: cashReceived.toFixed(2),
				checkReceived: checkReceived.toFixed(2),
				cashDue: cashDue.toFixed(2),
				totalAmountDue: totalAmountDue.toFixed(2),
				totalReceived: totalReceived.toFixed(2),
				change: change.toFixed(2),
				originalTotalReceived: totalReceived.toFixed(2),
			};
		},
		error: (state, payload) => {
			if (
				payload.result.code === "TIME_OUT" ||
				timeoutErrorCodes.includes(payload.statusCode)
			) {
				state.timeoutError = payload.result;
				state.isGenericError = false;
			} else if (payload.result.code === "NOT_ACTIVE") {
				state.notActiveError = payload.result.message;
			} else {
				state.errorValidation = { legacyError: payload.result.details };
				state.isGenericError = true;
			}
			state.isValidating = false;
			state.isValidated = true;
			state.billSummaryFields = {
				...initialState.billSummaryFields,
				additionalCash: state.billSummaryFields.additionalCash,
			};
		},
		clearValidation: (state, { payload }) => {
			const params = { ...payload };
			const exclude = { ...params.exclude };
			state.verifyFields = {};
			state.successValidation = {};
			state.validTransactions = {};
			state.failedTransactions = {};
			state.isValidating = false;
			state.isValidated = false;
			state.isGenericError = false;
			state.notActiveError = "";
			state.timeoutError = {
				code: null,
				statusCode: null,
				message: "",
			};
			state.billSummaryFields = {
				...initialState.billSummaryFields,
				additionalCash: exclude.additionalCash
					? state.billSummaryFields.additionalCash
					: "",
			};
			state.validateMessageStatus = null;
			state.currentTxnGroupNo = null;
		},
		resetValidated: (state, { payload }) => {
			state.isValidated = false;
			state.isGenericError = false;
			state.notActiveError = "";
			state.successValidation = {};
			state.validTransactions = {};
			state.failedTransactions = {};
			state.validateMessageStatus = null;
			state.currentTxnGroupNo = null;
			payload?.exclude !== "timeoutError"
				? (state.timeoutError = {
						code: null,
						statusCode: null,
						message: "",
				  })
				: "";
		},
		addCash: (state) => {
			let additionalCash = 0.0;

			if (!isNaN(parseFloat(state.billSummaryFields.additionalCash))) {
				additionalCash = parseFloat(
					state.billSummaryFields.additionalCash
				);
			}
			const totalReceived =
				parseFloat(state.billSummaryFields.originalTotalReceived) +
				additionalCash;
			const totalAmountDue = parseFloat(
				state.billSummaryFields.totalAmountDue
			);
			const cashDue = totalAmountDue - totalReceived;
			const change = totalReceived - totalAmountDue;
			state.billSummaryFields.totalReceived = totalReceived.toFixed(2);
			state.billSummaryFields.cashDue = cashDue.toFixed(2);
			state.billSummaryFields.change = change.toFixed(2);
		},
		updatePartnerSummary: (state, { payload }) => {
			let mergedPartnerTransactions = [];
			for (const key in payload) {
				// Merge the arrays of objects
				mergedPartnerTransactions = mergedPartnerTransactions.concat(
					payload[key]
				);
			}

			let totalBillDue = 0;
			let totalConvenienceFee = 0;
			let billCount = 0;
			let totalBankCharge = 0.0;
			let totalChannelFee = 0.0;
			for (const txn of mergedPartnerTransactions) {
				billCount += 1;
				totalBillDue =
					totalBillDue + (txn.amount ? parseFloat(txn.amount) : 0.0);
				totalConvenienceFee =
					totalConvenienceFee +
					(txn.otherCharges ? parseFloat(txn.otherCharges) : 0.0);
			}
			let checkReceived =
				state.paymentDetails.otherInfo &&
				state.paymentDetails.otherInfo.CheckDetails &&
				state.paymentDetails.otherInfo.CheckDetails.Amount
					? parseFloat(
							state.paymentDetails.otherInfo.CheckDetails.Amount
					  )
					: parseFloat("0.00");
			let cashReceived =
				state.paymentDetails.otherInfo &&
				state.paymentDetails.otherInfo.initialCash
					? parseFloat(state.paymentDetails.otherInfo.initialCash)
					: parseFloat("0.00");
			let totalReceived = cashReceived + checkReceived;
			let totalAmountDue =
				totalBillDue +
				totalConvenienceFee +
				totalBankCharge +
				totalChannelFee;
			let cashDue = totalAmountDue - totalReceived;
			let change = totalReceived - totalAmountDue;
			state.billSummaryFields = {
				billCount: billCount,
				totalBillDue: totalBillDue.toFixed(2),
				totalConvenienceFee: totalConvenienceFee.toFixed(2),
				totalBankCharge: totalBankCharge.toFixed(2),
				totalChannelFee: totalChannelFee.toFixed(2),
				cashReceived: cashReceived.toFixed(2),
				checkReceived: checkReceived.toFixed(2),
				cashDue: cashDue.toFixed(2),
				totalAmountDue: totalAmountDue.toFixed(2),
				totalReceived: totalReceived.toFixed(2),
				change: change.toFixed(2),
				originalTotalReceived: totalReceived.toFixed(2),
			};
		},
		addConvenienceFee: (state) => {
			let convenienceFee = 0.0;

			if (!isNaN(parseFloat(state.billSummaryFields.convenienceFee))) {
				convenienceFee = parseFloat(
					state.billSummaryFields.convenienceFee
				);
			}
			const totalReceived =
				parseFloat(state.billSummaryFields.originalTotalReceived) +
				convenienceFee;
			const totalAmountDue = parseFloat(
				state.billSummaryFields.totalAmountDue
			);
			const cashDue = totalAmountDue - totalReceived;
			const change = totalReceived - totalAmountDue;
			state.billSummaryFields.totalReceived = totalReceived.toFixed(2);
			state.billSummaryFields.cashDue = cashDue.toFixed(2);
			state.billSummaryFields.change = change.toFixed(2);
		},
		setPaymentDetails: (state, { payload }) => {
			state.paymentDetails = payload;
		},
		setAdditionalCash: (state, { payload }) => {
			state.billSummaryFields = {
				...state.billSummaryFields,
				additionalCash: payload,
			};
		},
		setConvenienceFee: (state, { payload }) => {
			state.billSummaryFields = {
				...state.billSummaryFields,
				convenienceFee: payload,
			};
		},
		clearErrorModalMessage: (state) => {
			state.errorModalMessage = undefined;
		},
		setErrorModalMessage: (state, { payload }) => {
			state.errorModalMessage = payload;
		},
		setCodeIdentifier: (state, { payload }) => {
			state.codeIdentifier = payload;
		},
		resetBillSummaryFields: (state) => {
			state.billSummaryFields = initialState.billSummaryFields;
		},
		setCurrentWithDeduction: (state, { payload }) => {
			state.currentWithDeduction = payload;
		},
		setValidationMessageStatus: (state, { payload }) => {
			state.validateMessageStatus = payload;
		},
		setCurrentTxnGroupNo: (state, { payload }) => {
			state.currentTxnGroupNo = payload;
		},
		setCurrentPartnerValidations: (state, { payload }) => {
			state.currentPartnerValidations = payload;
		},
		setTimeoutError: (state, { payload }) => {
			state.timeoutError = payload;
		},
	},
});

const {
	prep,
	load,
	error,
	clearValidation,
	resetValidated,
	addCash,
	setPaymentDetails,
	setAdditionalCash,
	setConvenienceFee,
	addConvenienceFee,
	clearErrorModalMessage,
	resetBillSummaryFields,
	updatePartnerSummary,
	setCurrentWithDeduction,
	setValidationMessageStatus,
	setCurrentTxnGroupNo,
	setCurrentPartnerValidations,
	setTimeoutError,
} = validationBuyLoad.actions;

const prepPaymentFields = (paymentFields) => {
	let newPaymentFields = {};
	Object.keys(paymentFields).forEach((code) => {
		let sanitizedCode = removeSpecialCharAndSpace(code); //remove special characters and space
		newPaymentFields[sanitizedCode] = [];
		paymentFields[code].forEach((valid) => {
			newPaymentFields[code].push({
				info: {
					validationNumber: valid.validationNumber,
					callbackUrl: process.env.REACT_APP_INVOKE_URL,
				},
				other: valid.otherInfo,
			});
		});
	});
	return newPaymentFields;
};

const isRegular = (partnerCode) => {
	const list = getCookie("service_fee");
	const foundItem = list.find((item) => item.code === partnerCode);

	if (foundItem.isRegular == 1) {
		return true;
	} else {
		return false;
	}
};

const getServiceFee = (partnerCode) => {
	const list = getCookie("service_fee");
	const foundItem = list.find((item) => item.code === partnerCode);

	return foundItem ? foundItem.otherCharges : null;
};

const verifyTransaction = async (client, state, dispatch) => {
	const branch = BranchUtil.get()[0];
	const terminal = TerminalUtil.get();
	dispatch(setShowAlert(false));
	let transactionsValidated = {};
	let totalBillDue = 0.0;
	const { selectedPaymentMethod: paymentMethod } = state.partnerList;

	let requestArray = [];
	let verifyResponses = [];
	let isSuccess = false;
	let partnerFeeFailed = [];
	let customerDetails =
		state.validationBuyLoad.paymentDetails.otherInfo.CustomerDetails;

	console.log(
		"state data in api call >>",
		state.partnerList.partnerTransactions
	);

	for (const code of Object.keys(state.partnerList.partnerTransactions)) {
		console.log("inside 1st for loop>>>>");
		for (const txn of state.partnerList.partnerTransactions[code]) {
			console.log("inside 2nd for loop>>>>");
			//get session service fee
			//check if partner exist and regular
			// true skip call of s.fee api
			// if false proceed to api
			let response;
			let otherCharges;
			// return { data: "done" };
			console.log("Regular>>>>>>>>>>", isRegular(code));
			if (isRegular(code) == false) {
				response = await client
					.get(`/v3/buyload/${code}/fees?amount=${txn.amount}`)
					.catch(({ response }) => {
						partnerFeeFailed.push(code);
						if (response.status === 400) {
							throw {
								response: {
									data: {
										code: "TIME_OUT",
										statusCode: response.status || 500,
										message: `${PARTNER_DISABLED_MESSAGE}`,
										from: "Partner Fees API",
									},
								},
							};
						} else if (response.status === 422) {
							isSuccess = false;
							verifyResponses.push({
								body: {
									...response.data,
									errors: {
										amount: [
											{
												code: 6,
												message:
													response.data.details
														.message,
											},
										],
									},
									code: code,
									amount: txn.amount,
									errorCode: "API3_ERROR_VALIDATE",
									transactionKey: txn.transactionKey,
									otherCharges: "0.00",
									referenceNumber: txn.referenceNumber,
								},
							});
							let verifyFields = buildVerifyFields(
								txn,
								paymentMethod,
								state.validationBuyLoad.paymentDetails,
								"0.00"
							);

							requestArray.push({ ...verifyFields, code });
						} else {
							throw {
								response: {
									data: {
										code: "TIME_OUT",
										statusCode: response.status || 500,
										message:
											"Unable to retrieve partner fees. Please try again.",
										from: "Partner Fees API",
									},
								},
							};
						}
					});
			}

			if (partnerFeeFailed.length === 0) {
				console.log("inside partner fiald block>>>");
				if (isRegular(code)) {
					otherCharges = getServiceFee(code);
				} else {
					otherCharges = response["data"]["data"]["otherCharges"];
				}

				dispatch(
					updatePartnerTxn({
						code: code,
						txnKey: txn.transactionKey,
						key: "otherCharges",
						value: otherCharges,
					})
				);

				totalBillDue =
					totalBillDue +
					(txn.amount
						? parseFloat(txn.amount) + parseFloat(otherCharges)
						: 0.0);

				let verifyFields = buildVerifyFields(
					txn,
					paymentMethod,
					state.validationBuyLoad.paymentDetails,
					otherCharges
				);

				requestArray.push({ ...verifyFields, code });
			}
		}
	}

	console.log("req array>>>", requestArray);

	if (partnerFeeFailed.length === 0) {
		/* Verify wallet and get group_ref_num (txn group validation ref) */
		let walletValidatePayload = {
			commit_payment: false,
			payment_details: requestArray,
			group_ref_num: null,
			branch_id: branch.id,
			terminal_id: terminal.id,
		};
		if (!isEmpty(customerDetails)) {
			walletValidatePayload = {
				...walletValidatePayload,
				customerDetails: customerDetails,
			};
			walletValidatePayload.payment_details.forEach((data) => {
				unset(data, "customerDetails");
				unset(data?.otherInfo, "CustomerDetails");
			});
		}
		const verifyWallet = await client
			.post("/v3/wallet/validate", JSON.stringify(walletValidatePayload))
			.then(({ data }) => data)
			.catch(({ response }) => {
				/* If timed out */
				if (timeoutErrorCodes.includes(response.status)) {
					throw {
						response: {
							data: {
								code: "TIME_OUT",
								statusCode: response.status || 504,
								message:
									"Unable to establish connection to wallet service. Please try again.",
								from: "Verify Wallet",
							},
						},
					};
				}

				if (response.status === 400) {
					dispatch(setIsDisabledPartnerDialogOpen(true));
					throw { message: "Product not available" };
				}
				if (response.status === 406) {
					const msg =
						response.data.message ===
						"Status of Partner is inactive"
							? `${PARTNER_DISABLED_MESSAGE}`
							: response?.data?.message;
					throw {
						response: {
							data: {
								code: "NOT_ACTIVE",
								statusCode: 406,
								message: `${msg}`,
							},
						},
					};
				}
				/* If has channel wallet error, add to responses array */
				if (response.status === 404) {
					throw {
						response: {
							data: {
								code: "TIME_OUT",
								statusCode: response.status || 422,
								message: `${PARTNER_DISABLED_MESSAGE}`,
							},
						},
					};
				}
				if (response.status === 422) {
					isSuccess = false;
					const txnValidation = response?.data?.txn_validation;
					const channelValidation = response?.data?.channel_wallet;
					/*if bonded, allow to process */
					if (
						!isEmpty(txnValidation) ||
						!isEmpty(channelValidation)
					) {
						!isEmpty(txnValidation) &&
							txnValidation.forEach((tv) =>
								verifyResponses.push(tv)
							);
						!isEmpty(channelValidation) &&
							channelValidation.data.forEach((cv) =>
								verifyResponses.push(cv)
							);
						return response.data;
					}
				}

				/* Default error */
				throw {
					response: {
						data: {
							code: "TIME_OUT",
							statusCode: response.status || 422,
							message:
								"Unable to establish connection to wallet service. Please try again.",
						},
					},
				};
			});

		verifyWallet &&
			dispatch(setCurrentTxnGroupNo(verifyWallet.txn_group_ref));

		/* If no wallet error, proceed
	    if channel wallet is bonded even below balance, continue to validate
	  */
		if (
			(isEmpty(verifyWallet?.channel_wallet) &&
				isEmpty(verifyWallet?.txn_validation)) ||
			(isEmpty(verifyWallet?.txn_validation) &&
				verifyWallet?.wallet_type === "bonded")
		) {
			dispatch(
				setValidationMessageStatus(
					"Validating the transaction details..."
				)
			);
			let unsuccessfulRequests = []; //Timeout transactions list
			let unsuccessfulRequestsNonRetriable = []; //Non-timeout transactions (Internal server error)

			/* Update partners to validate status */
			await client.put(
				`/v2/billers/transaction-group/${verifyWallet.txn_group_ref}`,
				{
					status: "VALIDATE_BILLERS",
				}
			);

			await Promise.all(
				requestArray.map(
					async (request, index) =>
						new Promise((resolve) => {
							console.log("req array map function promise");
							/* Verify billers and send group_ref_num */
							if (!isEmpty(customerDetails)) {
								request = {
									...request,
									customerDetails: customerDetails,
								};
							}
							request.sequenceNumber = index + 1;
							const body = JSON.stringify({
								...request,
								txnGrpRefNumber: verifyWallet.txn_group_ref,
							});
							/* Verify billers and send group_ref_num */
							api.post(
								`${API3_URL}/v3/buyload/${request.code}/accounts/${request.referenceNumber}`,
								body
							)
								.then(async ({ data }) => {
									verifyResponses.push({
										body: { ...data, ...request },
									});
									isSuccess = true;
									resolve({ success: 1 });
								})
								.catch((error) => {
									// console.log("account error>>>", error);
									const status = error?.response?.status;
									const data = error?.response?.data;

									if (status === 422) {
										const { errorCode } =
											error?.response?.data;
										verifyResponses.push({
											body: { ...data, ...request },
										});

										if (
											errorCode ==
												"TXN_GROUP_INVALID_STATUS" ||
											errorCode == "TXN_GROUP_NOT_FOUND"
										)
											dispatch(
												setTimeoutError({
													code: "ERROR",
													statusCode: 422,
													message:
														"Error 422: Validate Payment - Transaction group error. Kindly try to validate payment again.",
												})
											);
										isSuccess = false;
									} else if (status === 400) {
										const { errorCode } =
											error?.response?.data;
										verifyResponses.push({
											body: { ...data, ...request },
										});

										if (errorCode == "GENERIC_ERROR")
											dispatch(
												setTimeoutError({
													code: "ERROR",
													statusCode: 400,
													message:
														"Error 400: Validate Payment - We encountered a problem while processing your payment. Kindly try to validate payment again.",
												})
											);
										isSuccess = false;
									} else if (typeof status != "undefined") {
										/* Return successful validations */
										unsuccessfulRequests.push({
											request,
											body,
											status: status,
										});
									} else {
										//If error response is empty (50X), set too 500 error
										isSuccess = false;
										unsuccessfulRequestsNonRetriable.push({
											request,
											body,
											status: 500,
										});
										//show this error in banner
										dispatch(
											setTimeoutError({
												code: "ERROR",
												statusCode: 500,
												message:
													status === 504 ||
													status === 500
														? `${PARTNER_DISABLED_MESSAGE}`
														: "Unable to establish connection to biller. Please try again.",
											})
										);
									}

									resolve({ success: 1 });
								});
						})
				)
			);

			/* Start processing unsuccessful billers */
			if (unsuccessfulRequests.length > 0) {
				const options = {
					statusCodes: timeoutErrorCodes,
					status: 504, //placeholder status to enter retry recursion
					tries: 3,
					timeout: 5,
				};

				const callback = async (i) => {
					//update transaction group status to retry
					return client
						.put(
							`/v2/billers/transaction-group/${verifyWallet.txn_group_ref}`,
							{
								status: "VALIDATE_BILLERS_RETRY",
							}
						)
						.then(() => {
							return new Promise((resolve, reject) => {
								let __tempUnsuccessfulRequests = [];

								Promise.all(
									unsuccessfulRequests.map(
										async ({ request, body, status }) => {
											const partnerNameIfMulti =
												requestArray.length > 1
													? state.partnerList.initialPartnersLoaded.find(
															(b) =>
																b.code ===
																request.code
													  )?.name + " - "
													: "";

											dispatch(
												setValidationMessageStatus(
													`Retrying to validate transaction details...(${partnerNameIfMulti}${moment(
														`01/${i + 1}`
													).format("Do")})`
												)
											);
											/* Excute retry API */
											return api
												.post(
													`${API3_URL}/v3/buyload/${request.code}/accounts/${request.referenceNumber}/retry`,
													body
												)
												.then(({ data }) => {
													/* When successful, push it to verify responses */

													/**/
													verifyResponses.push({
														body: {
															...data,
															...request,
														},
													});
												})
												.catch((error) => {
													const status =
														error?.response?.status;
													const data =
														error?.response?.data;

													if (status === 422) {
														verifyResponses.push({
															body: {
																...data,
																...request,
															},
														});
													} else if (
														typeof status !=
														"undefined"
													) {
														__tempUnsuccessfulRequests.push(
															{
																request,
																body,
																status: status,
															}
														);
													} else {
														unsuccessfulRequestsNonRetriable.push(
															{
																request,
																body,
																status: 500,
															}
														);
														dispatch(
															setTimeoutError({
																code: "ERROR",
																statusCode: 500,
																message:
																	status ===
																		504 ||
																	status ===
																		500
																		? `${PARTNER_DISABLED_MESSAGE}`
																		: "Unable to establish connection to biller. Please try again.",
															})
														);
													}
												});
										}
									)
								)
									.then((e) => {
										if (
											__tempUnsuccessfulRequests.length >
											0
										) {
											unsuccessfulRequests = [
												...__tempUnsuccessfulRequests,
											];
											reject({
												response: {
													response: {
														data: {
															...(error?.response
																?.data ?? {}),
															code: "TIME_OUT",
															statusCode:
																error?.status ||
																504,
															message: `${PARTNER_DISABLED_MESSAGE}`,
															from: "Biller Validate",
														},
													},
												},
											});
										}
										resolve({ success: 1 });
									})
									.catch((e) => {
										resolve({ success: 1 });
									});
							});
						});
				};

				try {
					await retry_f(options, callback);

					//if all transactions are successful
					isSuccess = true;
				} catch (e) {
					//if at least 1 transaction failed
					isSuccess = false;
					if (e.response.data.statusCode == 400) {
						dispatch(
							setTimeoutError({
								code: "TIME_OUT",
								statusCode: 400,
								message:
									"Unable to establish connection to biller. Please try again.",
							})
						);
					}
					if (
						e.response.data.statusCode == 504 ||
						e.response.data.statusCode == 500
					) {
						dispatch(
							setTimeoutError({
								code: "TIME_OUT",
								statusCode: 504,
								message: `${PARTNER_DISABLED_MESSAGE}`,
							})
						);
					}

					unsuccessfulRequests.forEach((req) => {
						verifyResponses.push({
							body: {
								code: req.request.code,
								transactionKey: req.request.transactionKey,
								data: {
									valid: false,
								},
								errors: {
									error: [
										{
											code: "TIME_OUT",
											message:
												req.request.code == 504 ||
												req.request.code == 500
													? `${PARTNER_DISABLED_MESSAGE}`
													: "Unable to establish connection to biller. Please try again.",
										},
									],
								},
							},
						});
					});
				}

				//To disable commit button when error 50X
				unsuccessfulRequestsNonRetriable.forEach((req) => {
					verifyResponses.push({
						body: {
							code: req.request.code,
							transactionKey: req.request.transactionKey,
							data: {
								valid: false,
							},
							errors: {
								error: [
									{
										code: "TIME_OUT",
										message:
											req.request.code == 504 ||
											req.request.code == 500
												? `${PARTNER_DISABLED_MESSAGE}`
												: "Unable to establish connection to biller. Please try again.",
									},
								],
							},
						},
					});
				});
			}

			await client.put(
				`/v2/billers/transaction-group/${verifyWallet.txn_group_ref}`,
				{
					status: isSuccess
						? "BILLER_VALIDATED"
						: "BILLER_VALIDATION_FAILED",
				}
			);
		}
	}

	/* Update group_ref_num to success or failed */
	dispatch(setCurrentPartnerValidations(verifyResponses));

	const responseData = { data: verifyResponses };

	try {
		responseData["data"].forEach((dataValue) => {
			const message = dataValue.body.data?.message;
			const identifier = dataValue.body.data?.code;
			if (identifier === 1) {
				if (!_.isEmpty(message)) {
					dispatch(
						validationBuyLoad.actions.setErrorModalMessage(message)
					);
					dispatch(
						validationBuyLoad.actions.setCodeIdentifier(identifier)
					);
				}
			} else if (identifier === 2) {
				if (!_.isEmpty(message)) {
					dispatch(
						validationBuyLoad.actions.setErrorModalMessage(message)
					);
					dispatch(
						validationBuyLoad.actions.setCodeIdentifier(identifier)
					);
				}
			}
		});
	} catch (e) {
		console.log("Error reading message validate: ", e);
	}

	const params = { requestArray, transactionsValidated, dispatch, state };

	if ("balance_flag" in responseData) {
		if ("data" in responseData.balance_flag) {
			responseData.balance_flag.data.forEach(validate(params));
		}
	}

	responseData.data.forEach(validate(params));
	return { data: { transactionsValidated, requestArray } };
};

function validate({ requestArray, transactionsValidated, dispatch, state }) {
	return function (resp) {
		const code = resp.body.code;
		const reqData = requestArray.find(
			(ra) => ra.transactionKey === resp.body.transactionKey
		);
		if (resp.body?.data?.valid) {
			transactionsValidated[code] = transactionsValidated[code]
				? transactionsValidated[code]
				: [];
			transactionsValidated[code].push({
				...resp.body.data,
				code,
				amount: reqData?.amount,
				transactionKey: reqData?.transactionKey,
				otherCharges: reqData?.otherCharges,
				otherInfo: reqData?.otherInfo || {},
				referenceNumber: reqData?.referenceNumber,
				paymentMethod: reqData?.paymentMethod,
				sequenceNumber: reqData?.sequenceNumber,
			});
		} else {
			const errors =
				resp.body.type === "wallet_balance_validation"
					? { wallet_balance: [resp.body.errors] }
					: resp.body.errors;
			showWalletAlert(errors, dispatch, state);
			if (
				code !== undefined ||
				(code === undefined && state.walletBalance.type === "prefunded")
			) {
				transactionsValidated[code] = transactionsValidated[code]
					? transactionsValidated[code]
					: [];
				transactionsValidated[code].push({
					errors,
					code,
					valid: false,
					amount: reqData?.amount,
					transactionKey: reqData?.transactionKey,
					otherCharges: reqData?.otherCharges,
					otherInfo: reqData?.otherInfo,
					referenceNumber: reqData?.referenceNumber,
					paymentMethod: reqData?.paymentMethod,
					sequenceNumber: reqData?.sequenceNumber,
				});
			}
		}
	};
}

function showWalletAlert(errors, dispatch, state) {
	for (const field of Object.values(errors)) {
		const { isProductTypeError, isWalletBalanceError } =
			validateErrors(field);

		const isWalletPrefunded = state.walletBalance.type === "prefunded";

		if (isWalletBalanceError && !isProductTypeError) {
			dispatch(setShowAlert(true));
			dispatch(setIsWalletSufficient(isWalletPrefunded ? false : true));
		} else {
			dispatch(setIsWalletSufficient(true));
		}
	}
}

function validateErrors(errors) {
	let isProductTypeError = false,
		isWalletBalanceError = false;
	for (const error of errors) {
		const { code, message } = error;
		const walletBalanceMessage = [
			"The wallet balance of the customer is below",
			"The wallet balance is insufficient to process",
		];

		// Code for [wallet balance | product wallet balance | product type] error: 17
		if (code === 17 || code === "CHANNEL_BALANCE_INSUFFICIENT") {
			if (
				message.search("Not allowed to process this Product Type") > -1
			) {
				// Product type unticked
				isProductTypeError = true;
			} else if (
				walletBalanceMessage.some((msg) => message.search(msg) > -1)
			) {
				// Insufficient wallet balance of customer
				isWalletBalanceError = true;
			}
		}
	}

	return { isProductTypeError, isWalletBalanceError };
}

function buildVerifyFields(txn, paymentMode, paymentDetails, otherCharges) {
	let cloneTxn = JSON.parse(JSON.stringify(txn));
	dot.object(cloneTxn);
	cloneTxn["otherCharges"] = otherCharges;
	cloneTxn["paymentMethod"] = paymentMode;
	let clonePaymentDetails = JSON.parse(JSON.stringify(paymentDetails));
	dot.object(clonePaymentDetails);
	if (paymentMode === "CHECK") {
		cloneTxn["otherInfo"] = cloneTxn["otherInfo"]
			? cloneTxn["otherInfo"]
			: {};
		cloneTxn["otherInfo"] = {
			...cloneTxn["otherInfo"],
			...clonePaymentDetails.otherInfo,
		};

		//default to local
		if (!cloneTxn["otherInfo"]["CheckDetails"]["CheckType"]) {
			cloneTxn["otherInfo"]["CheckDetails"]["CheckType"] = "Local";
		}
		if (!cloneTxn["otherInfo"]["CheckDetails"]["BankAccountNumber"]) {
			cloneTxn["otherInfo"]["CheckDetails"]["BankAccountNumber"] = "";
		}
		if (!cloneTxn["otherInfo"]["CheckDetails"]["BankBranch"]) {
			cloneTxn["otherInfo"]["CheckDetails"]["BankBranch"] = "";
		}
		if (!cloneTxn["otherInfo"]["CheckDetails"]["BankCode"]) {
			cloneTxn["otherInfo"]["CheckDetails"]["BankCode"] = "";
		}
		if (!cloneTxn["otherInfo"]["CheckDetails"]["BankName"]) {
			cloneTxn["otherInfo"]["CheckDetails"]["BankName"] = "";
		}
		if (!cloneTxn["otherInfo"]["CheckDetails"]["CheckDate"]) {
			cloneTxn["otherInfo"]["CheckDetails"]["CheckDate"] = "";
		}
		if (!cloneTxn["otherInfo"]["CheckDetails"]["CheckNo"]) {
			cloneTxn["otherInfo"]["CheckDetails"]["CheckNo"] = "";
		}
		if (!cloneTxn["otherInfo"]["CheckDetails"]["ContactNumber"]) {
			cloneTxn["otherInfo"]["CheckDetails"]["ContactNumber"] = "";
		}
	}
	return cloneTxn;
}

function verifyActionCreator() {
	return {
		types: [prep.type, load.type, error.type],
		promise: verifyTransaction,
	};
}

export default validationBuyLoad.reducer;

export {
	verifyActionCreator,
	clearValidation,
	resetValidated,
	addCash,
	setPaymentDetails,
	setAdditionalCash,
	setConvenienceFee,
	addConvenienceFee,
	clearErrorModalMessage,
	resetBillSummaryFields,
	updatePartnerSummary,
	setCurrentWithDeduction,
	setValidationMessageStatus,
	setCurrentTxnGroupNo,
	setCurrentPartnerValidations,
	setTimeoutError,
};

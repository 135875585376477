import React, { Component } from "react";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@material-ui/core";
import {
	printCheckFlowModal,
	updateCheckList,
} from "../../redux/modules/transactionBuyLoad";
import styles from "./PrintCheckFlow.module.css";
import HTTP from "../../helpers/ApiClient";
import { connect } from "react-redux";
import { removeObjectSessionList } from "../../utils/storage";
import _ from "lodash";

class PrintCheckFlowBuyLoad extends Component {
	constructor() {
		super();
		this.state = {
			isPrintReceiptSuccessful: undefined,
			receiptNo: undefined,
			reason: "",
			isReceiptNumberValid: true,
			showModal: false,
			isLoading: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.generatePreFilledReceiptNo =
			this.generatePreFilledReceiptNo.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
	}

	toggleModal() {
		this.setState({ showModal: !this.state.showModal });
	}

	onSubmitButtonEnable() {
		const isPrintReceiptSuccessful = this.state.isPrintReceiptSuccessful;
		if (isPrintReceiptSuccessful === "no" && isPrintReceiptSuccessful) {
			if (this.state.reason !== "") {
				return false;
			}
			return true;
		}
		if (isPrintReceiptSuccessful === "yes") {
			return false;
		}
		if (isPrintReceiptSuccessful == undefined) {
			return true;
		}
	}

	handleChange(e) {
		const prefilledReceiptNo = this.generatePreFilledReceiptNo();
		this.setState({
			isPrintReceiptSuccessful: e.target.value,
			receiptNo: prefilledReceiptNo,
		});
	}

	resetStateComponent() {
		this.setState({
			isPrintReceiptSuccessful: undefined,
			receiptNo: undefined,
			reason: "",
			isReceiptNumberValid: true,
			isLoading: false,
		});
	}

	onInputChange(e) {
		const val = e.target.value;
		if (this.state.isPrintReceiptSuccessful === "yes") {
			this.setState({ receiptNo: val });
		} else {
			this.setState({ reason: val });
		}
	}

	async onSubmit() {
		this.setState({ isLoading: true });
		const receiptNo = this.props.checkReceiptsList[0].receiptNo;
		const data = {
			remarks: this.state.reason,
			status:
				this.state.isPrintReceiptSuccessful === "yes"
					? "PRINTED"
					: "FAILED",
		};
		HTTP.put(`/v2/receipts/check/${receiptNo}`, JSON.stringify(data))
			.then(() => {
				this.props.updateCheckList({ index: 0, status: data.status });
				this.setState({
					isReceiptNumberValid: true,
					isLoading: false,
				});
				this.props.printCheckFlowModal({ show: false });
				removeObjectSessionList(
					"transactionDetails",
					"checkReceiptNo",
					receiptNo
				);
			})
			.catch((error) => {
				console.log("Error: ", error.response.data);
				this.setState({
					isReceiptNumberValid: false,
					isLoading: false,
				});
			});
	}

	generatePreFilledReceiptNo() {
		const date = new Date();
		let month = date.getMonth() + 1;
		month = ("0" + month).slice(-2);
		const prefilledReceiptNo = `${month}${date.getFullYear()}-`;
		return prefilledReceiptNo;
	}

	render() {
		return (
			<Dialog
				aria-labelledby="print-receipt-confirmation"
				open={this.props.showModal}
				fullWidth={true}
				maxWidth={"sm"}
			>
				<Grid container alignItems="center" justify="center">
					<Grid item xs={12}>
						<DialogTitle
							id="print-receipt-confirmation"
							style={{ textAlign: "center" }}
						>
							{this.state.isPrintReceiptSuccessful ===
								undefined && (
								<Typography
									variant="h6"
									className={styles.modalTitle}
								>
									{" "}
									Receipt Check Printing Done!
								</Typography>
							)}
							{this.state.isPrintReceiptSuccessful !==
								undefined && (
								<Typography
									variant="h6"
									className={styles.modalTitle}
								>
									{" "}
									Receipt Printing Confirmation
								</Typography>
							)}
						</DialogTitle>
						<DialogContent
							dividers
							style={{ textAlign: "center", paddingTop: "30px" }}
						>
							<Typography className={styles.modalBody}>
								Is the receipt successfully printed?
							</Typography>
							<RadioGroup
								aria-label="print-result"
								name="print-result"
								row
								value={this.state.isPrintReceiptSuccessful}
								onChange={this.handleChange}
								style={{
									justifyContent: "center",
									padding: "10px 0 30px 0",
								}}
							>
								<FormControlLabel
									value="no"
									control={<Radio />}
									label={
										<Typography
											className={styles.modalBody}
										>
											No
										</Typography>
									}
									style={{ padding: "0 20px" }}
								/>
								<FormControlLabel
									value="yes"
									control={<Radio />}
									label={
										<Typography
											className={styles.modalBody}
										>
											Yes
										</Typography>
									}
									style={{ padding: "0 20px" }}
								/>
							</RadioGroup>
							{this.state.isPrintReceiptSuccessful === "no" && (
								<div style={{ padding: "30px 0 10px 0" }}>
									<TextField
										id="reason"
										name="reason"
										label="Reason"
										multiline
										rows={4}
										variant="outlined"
										fullWidth={true}
										inputProps={{ maxLength: 1000 }}
										InputLabelProps={{
											classes: {
												root: styles.modalBody,
											},
										}}
										InputProps={{
											classes: {
												input: styles.modalBody,
											},
										}}
										onChange={this.onInputChange}
									/>
								</div>
							)}
						</DialogContent>
						<DialogActions style={{ justifyContent: "center" }}>
							<Button
								disabled={
									this.onSubmitButtonEnable() ||
									this.state.isLoading
								}
								onClick={this.onSubmit}
								className={styles.enable}
							>
								{this.state.isLoading && (
									<CircularProgress
										size={18}
										style={{ marginRight: "6px" }}
									/>
								)}{" "}
								Submit
							</Button>
						</DialogActions>
					</Grid>
				</Grid>
			</Dialog>
		);
	}
}

export default connect(
	(state) => ({
		showModal: state.transactionBuyLoad.printCheckShow,
		checkReceiptsList: state.transactionBuyLoad.checkReceiptsList,
	}),
	{ updateCheckList, printCheckFlowModal }
)(PrintCheckFlowBuyLoad);

import { createSlice } from "@reduxjs/toolkit";
import { setSessionStorage } from "../../utils/storage";

const initialState = {
	banks: [],
	selectedBillerDetails: {},
	selectedPartnerDetails: {},
	status: "idle",
};

const banks = createSlice({
	name: "banks",
	initialState,
	reducers: {
		prep: (state) => {
			state.status = "loading";
		},
		load: (state, payload) => {
			state.status = "success";
			state.banks = payload.result.data;
			setSessionStorage("banks", payload.result.data);
		},
		error: (state, payload) => {
			state.status = "failed";
		},
		selectBiller: (state, payload) => {
			state.selectedBiller = payload.payload;
		},
		selectPartner: (state, payload) => {
			state.selectedPartner = payload.payload;
		},
		setBillerDetails: (state, payload) => {
			state.selectedBillerDetails = payload.result.data;
		},
		setPartnerDetails: (state, payload) => {
			state.selectedPartnerDetails = payload.result.data;
		},
		setBankList: (state, payload) => {
			state.banks = payload.payload;
		},
	},
});

const {
	prep,
	load,
	error,
	selectBiller,
	selectPartner,
	setBillerDetails,
	setPartnerDetails,
	setBankList,
} = banks.actions;

const getBanks = async (client) => {
	return client.get(`/v2/checks/banks`);
};

function getBillerDetailsAction(code = "") {
	return {
		types: [prep.type, setBillerDetails.type, error.type],
		promise: (client, state) => {
			if (code != "") {
				//FOR STICO
				return client.get(`/v2/checks/${state.banks.selectedBiller}`, {
					params: {
						schoolCode: code,
					},
				});
			}
			return client.get(`/v2/checks/${state.banks.selectedBiller}`);
		},
		code,
	};
}

function getPartnerDetailsAction(code = "") {
	return {
		types: [prep.type, setPartnerDetails.type, error.type],
		promise: (client, state) => {
			if (code != "") {
				//FOR STICO
				return client.get(`/v2/checks/${state.banks.selectedPartner}`, {
					params: {
						schoolCode: code,
					},
				});
			}
			return client.get(`/v2/checks/${state.banks.selectedPartner}`);
		},
		code,
	};
}

function getBanksAction() {
	return {
		types: [prep.type, load.type, error.type],
		promise: getBanks,
	};
}

export default banks.reducer;

export {
	selectBiller,
	selectPartner,
	getBanksAction,
	getBillerDetailsAction,
	getPartnerDetailsAction,
	setBankList,
};

import axios from "axios";
import { getSessionStorage, setSessionStorage } from "./storage";

const api3baseURL = process.env.REACT_APP_API3_ENDPOINT;
// console.log("api3baseURL", api3baseURL);

const api = axios.create({
	// Base URL for your API (if applicable)
	// baseURL: api3baseURL,
});

// Function to get a new access token (replace with your logic)
const getNewAccessToken = async () => {
	try {
		const username = process.env.REACT_APP_API3_USERNAME;
		const password = process.env.REACT_APP_API3_PASSWORD;
		const auth = {
			username: username,
			password: password,
		};

		const data = {
			grant_type: "client_credentials",
			tpa_id: "1B86",
			scope: "bip/channel",
		};

		const response = await axios.post(
			`${api3baseURL}/v3/oauth2/token`,
			data,
			{
				auth: auth,
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		if (response.data.access_token) {
			setSessionStorage("api3Token", response.data.access_token);
		}

		return response.data.access_token;
	} catch (error) {
		console.error("Error refreshing token:", error);
	}
};

// Interceptor for token validation and refresh
api.interceptors.request.use(
	async (config) => {
		const accessToken = getSessionStorage("api3Token");
		if (
			config.method === "post" ||
			config.method === "put" ||
			config.method === "patch"
		) {
			config.headers["Content-Type"] = "application/json";
		}
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
		return config;
	},
	(error) => Promise.reject(error)
);

api.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;

		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;

			try {
				const newAccessToken = await getNewAccessToken();
				originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
				return api(originalRequest);
			} catch (refreshError) {
				console.error("Error refreshing token:", refreshError);
			}
		}

		return Promise.reject(error);
	}
);

export default api;
